import React, { useEffect, useState} from 'react';
import s from './Individual.module.scss';
import { 
    ApplicationStepStatus,
    ApplicationSteps, 
    ApplicationInformationData, 
    ResidentialInformationData,
    TradingInformationData,
    FinancialInformationData,
    BankingInformationData,
    IndividualDocumentsData,
    WalletAddressData,
    ReferralInformationData
} from '../../components/Types';
import { 
    saveApplicantInformation, 
    saveResidentialAddress,
    saveTradingInformation,
    saveFinancialInformation,
    saveBankingInformation,
    saveWalletInformation,
    saveDeclarationInformation,
    saveReferralInformation,
    submitIndividualApplication
} from '../../api/individual';
import {
    individualApplicantFormRequired,
    individualResidentialFormRequired,
    individualTradingFormRequiredWithOtherPurpose,
    individualTradingFormRequired,
    individualFinancialFormRequired,
    bankingFormRequired,
    bankingWithIntBank,
    referralFormRequired,
    walletAddressFormRequired
} from '../../constants/requiredFields';
import { getAuthUserInfo } from '../../api/applicantion';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ApplicationInformation, ResidentialInformation, TradingAccount, FinancialInformation, BankingInformation, UploadDocuments, WalletAddress, Declaration, KYC, Referral } from './forms';
import { Button, ThemeSwitchToggle, CornerToaster, Modal, TopCornerToaster } from '../../components/UILib';
import { fieldValidatingHandler, multiFormsValidatingHandler, multiFormsValidatingHandlerForBanks } from '../../utils/validations';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from '../../contexts/ThemeContext';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils/responsiveBreakpoints';

const initialStatus : ApplicationStepStatus = {
    'Application Information': false, //0
    'Residential Address': false, //1
    'Trading Account': false, //2
    'Financial Information': false, //3
    'Banking Information': false, //4
    'Proof of Funds': false, //5
    'Wallet Address': false, //6
    'Declaration': false, //7
    'KYC': true, //8
    'Referral': false //9
}
function Individual() {
    const navigate = useNavigate();
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    // const location = useLocation();
    // const { user } = location.state || {};
    const [user, setUser] = useState<any>();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const { theme, toggleTheme } = useTheme();
    const [curStep, setCurStep] = useState<number>(0);
    const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
    const [submissionOpen, setSubmissionOpen] = useState<boolean>(false);
    const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
    // information save
    const [informationSaveOpen, setInformationSaveOpen] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
    const [savedPage, setSavedPage] = useState<string>('');
    // validation alerts variables
    const [alertId, setAlertId] = useState<string>('');
    const [openValidation, setOpenValidation] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<React.ReactNode>('');
    const [alertSuccessId, setAlertSuccessId] = useState<string>('');
    const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
    // each page completion status
    const [formCompletionStatus, setFormCompletionStatus] = useState<ApplicationStepStatus>(initialStatus)
    const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    // page info
    const [applicationInformation, setApplicationInformation] = useState<ApplicationInformationData>({ 
        title: '', 
        first_name: '', 
        middle_name: '', 
        last_name: '', 
        date_of_birth: '', 
        country: '', 
        linkedin: '', 
        instagram: '', 
        twitter: ''
    });
    const [residentialInformation, setResidentialInformation] = useState<ResidentialInformationData>({
        street_address: '', 
        unit: '', 
        city: '', 
        state: '', 
        postal_code: '', 
        country: '', 
        phone_number: ''});
    const [tradingInformation, setTradingInformation] = useState<TradingInformationData>({
        purpose: [],
        other_purpose: '',
        purpose_description: '',
        flow_of_first_trade: '',
        date_of_first_trade: '',
        currency_of_first_trade: '',
        size_of_first_trade: '',
        currency_to_be_traded: '',
        monthly_volume_to_be_traded: '',
        funding_source: '',
    });
    const [financialInformation, setFinancialInformation] = useState<FinancialInformationData>({
        income_currency: '',
        income: '',
        liquid_asset_currency: '',
        liquid_asset_value: '',
        bankruptcy: false,
        bankruptcy_details: '',
        pep: false,
        pep_details: '',
        significant_transactions: false,
        large_investment_portfolio: false,
    });
    const [bankingInformation, setBankingInformation] = useState<BankingInformationData[]>([{
        bank_account_name: '',
        account_number: '',
        routing_code:'',
        swift_code: '',
        beneficiary_address: '',
        bank_name: '',
        bank_address: '',
        country: '',
        account_currency: '',
        has_intermediary_bank: false,
        intermediary_bank_name: '',
        intermediary_routing_number: '',
        intermediary_bank_address: '',
    }]);
    const [uploadDocuements, setUploadDocuments] = useState<IndividualDocumentsData>({
        type: 'proof_of_funds',
        doc_link: ''
    });
    const [walletAddress, setWalletAddress] = useState<WalletAddressData[]>([{
        type: '',
        wallet_address: ''
    }]);
    const [declaration, setDeclaration] = useState<boolean>(false);
    const [referralInformation, setReferralInformation] = useState<ReferralInformationData>({
        source: '',
        referred_by: '',
    });
    // field error states
    const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
    const [invalidFormInputs, setInvalidFormInputs] = useState<any[]>([]);
    const individualSteps: ApplicationSteps = {
        'Application Information': 'ApplicationInformation', //0
        'Residential Address': 'ResidentialInformation', //1
        'Trading Account': 'TradingAccount', //2
        'Financial Information': 'FinancialInformation', //3
        'Banking Information': 'BankingInformation', //4
        'Proof of Funds': 'UploadDocuments', //5
        'Wallet Address': 'WalletAddress', //6
        'Declaration': 'Declaration', //7
        'KYC': 'KYC', //8
        'Referral': 'Referral' //9
    }
    const stepsArray = Object.keys(individualSteps)

    useEffect(() => {
        if(userId) {
            getUserInfo(Number(userId))
        }
        else {
            //TODO: ERROR ALERT -> CANT RETRIEVE USER INFO
        }
    },[userId])
    const getUserInfo = async(userId: number) => {
        let resUser = await getAuthUserInfo(userId)
        console.log('getUserInfo',resUser);
        setUser(resUser)
        return resUser
    }
    useEffect(() => {
        console.log(user);
        if(user){
            // map it to application 
            let applicantInfo = user["individual_applicant_information"]
            if(applicantInfo){
                setApplicationInformation({
                    title: applicantInfo.title || '',
                    first_name: applicantInfo.first_name || '',
                    middle_name: applicantInfo.middle_name || '', // Ensure undefined fields are set to empty strings
                    last_name: applicantInfo.last_name || '',
                    date_of_birth: applicantInfo.date_of_birth || '',
                    country: applicantInfo.country || '',
                    linkedin: applicantInfo.linkedin || '', // Handle optional fields
                    instagram: applicantInfo.instagram || '',
                    twitter: applicantInfo.twitter || ''
                });
            }
            
            let residentialInfo = user["individual_residential_address"]
            if(residentialInfo) {
                setResidentialInformation({
                    street_address: residentialInfo.street_address || '', 
                    unit: residentialInfo.unit || '', 
                    city: residentialInfo.city || '',
                    state: residentialInfo.state || '',
                    postal_code: residentialInfo.postal_code || '',
                    country: residentialInfo.country || '',
                    phone_number: residentialInfo.phone_number || '',
                })
            }
            
            let tradinglInfo = user["individual_trading_information"]
            if(tradinglInfo) {
                setTradingInformation({
                    purpose: tradinglInfo.purpose || [],
                    other_purpose: tradinglInfo.other_purpose ||'',
                    purpose_description: tradinglInfo.purpose_description ||'',
                    flow_of_first_trade: tradinglInfo.flow_of_first_trade || '',
                    date_of_first_trade: tradinglInfo.date_of_first_trade ||'',
                    currency_of_first_trade: tradinglInfo.currency_of_first_trade ||'',
                    size_of_first_trade: tradinglInfo.size_of_first_trade ||'',
                    currency_to_be_traded: tradinglInfo.currency_to_be_traded ||'',
                    monthly_volume_to_be_traded: tradinglInfo.monthly_volume_to_be_traded ||'',
                    funding_source: tradinglInfo.funding_source ||'',
                })
            }
            let financialInfo = user["individual_financial_information"]
            if(financialInfo) {
                setFinancialInformation({
                    income_currency: financialInfo.income_currency || '',
                    income: financialInfo.income ||'',
                    liquid_asset_currency: financialInfo.liquid_asset_currency ||'',
                    liquid_asset_value: financialInfo.liquid_asset_value ||'',
                    bankruptcy: financialInfo.bankruptcy || false,
                    bankruptcy_details: financialInfo.bankruptcy_details || '',
                    pep: financialInfo.pep || false,
                    pep_details: financialInfo.pep_details || '',
                    significant_transactions: financialInfo.significant_transactions || false,
                    large_investment_portfolio: financialInfo.large_investment_portfolio || false,
                })
            }
            let bankingInfo = user["individual_banking_information"]
            if(bankingInfo.length > 0) {
                setBankingInformation(bankingInfo)
            }
            let docInfo = user["individual_documents"]
            if(docInfo){
                let fund = docInfo.filter((doc:IndividualDocumentsData) => doc.type === 'proof_of_funds')[0]
                if(fund){
                    setUploadDocuments((prevState) => ({...prevState, doc_link:fund.doc_link || '' }))
                }
                
            }
            let walletInfo = user["wallet_addresses"]
            if(walletInfo.length > 0) {
                setWalletAddress(walletInfo)
            }
            let declarationInfo = user["declaration"]
            if(declarationInfo){
                setDeclaration(declarationInfo.signed)
            }
            let referralInfo = user["referral_informations"]
            if(referralInfo) {
                setReferralInformation({
                    source: referralInfo.source || '',
                    referred_by: referralInfo.referred_by || '',
                })
            }
        }
        
    },[user])
    const handleStepperClick = async(step: keyof typeof individualSteps, index: number) => {
        console.log('prev step:', curStep)
        console.log('cur step:', step)
        handlePagesInfoSave(curStep, false)
        //setInformationSaveOpen(false)
        setOpenValidation(false)
        setValidationSuccess(false)
        setCurStep(index)
    }
    const onNextPage = async() => {
        //setInformationSaveOpen(false)
        setOpenValidation(false)
        setValidationSuccess(false)
        if (curStep < stepsArray.length - 1) {
            handlePagesInfoSave(curStep, false)
            setCurStep(curStep + 1);
        } else {
            console.log('Submit form');
        }
    }
    const onPreviousPage = async() => {
        setOpenValidation(false)
        setValidationSuccess(false)
        if (curStep > 0) {
            handlePagesInfoSave(curStep, false)
            setCurStep(curStep - 1);
            
        } else {
            console.log('First step, cannot move back');
        }
    };
    const handleLogOut = () => {
        navigate('/');
    }
    // for next and previous buttons
    const handlePagesInfoSave = async(curAppStep: number, notify: boolean) => {
        console.log('curAppStep', curAppStep);
        setPageLoading(true)
        let res : any;
        if(userId) {
            
            if(curAppStep === 0) {
                res = await saveApplicantInformation(Number(userId),applicationInformation)
                console.log(res);
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 1) {
                res = await saveResidentialAddress(Number(userId), residentialInformation)
                console.log(res);
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 2) {
                res = await saveTradingInformation(Number(userId), tradingInformation)
                console.log(res);
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 3) {
                res = await saveFinancialInformation(Number(userId), financialInformation)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 4) {
                res = await saveBankingInformation(Number(userId), bankingInformation)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 6) {
                res = await saveWalletInformation(Number(userId), walletAddress)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 7) {
                res = await saveDeclarationInformation(Number(userId), declaration)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 9) {
                res = await saveReferralInformation(Number(userId), referralInformation)
                setSavedPage(stepsArray[curAppStep])
            }
            console.log(stepsArray[curAppStep], res);
            
            if(res && res.status === 201 && notify){
                setInformationSaveOpen(true)
                setSaveSuccess(true)
            }
            setPageLoading(false)
            // else {
            //     setInformationSaveOpen(true)
            //     setSaveSuccess(true)
            // }
        }
        else {
            // TODO: userid not present in url, throw error
            console.log('no user id');
            setPageLoading(false)
            if(notify) {
                setInformationSaveOpen(true)
                setSaveSuccess(false)
            }
        }
    }
    useEffect(() => {
        // update completion status
        let appInfo = fieldValidatingHandler(individualApplicantFormRequired, applicationInformation)
        let resInfo = fieldValidatingHandler(individualResidentialFormRequired, residentialInformation)
        let tradeInfo = tradingInformation.purpose.includes('Other')? 
            fieldValidatingHandler(individualTradingFormRequiredWithOtherPurpose, tradingInformation)
            :fieldValidatingHandler(individualTradingFormRequired, tradingInformation)
        let finInfo = fieldValidatingHandler(individualFinancialFormRequired, financialInformation)
        let bankInfo = multiFormsValidatingHandlerForBanks(bankingFormRequired, bankingWithIntBank, bankingInformation)
        let bank =  bankInfo.every(arr => arr.length === 0) ? true: false
        let walletInfo = multiFormsValidatingHandler(walletAddressFormRequired, walletAddress)
        console.log('walletInfo', walletInfo);
        
        let wallet = walletInfo.every(arr => arr.length === 0) ? true: false
        console.log('wallet', wallet);
        
        //let upload = uploadDocuements.doc_link !== ''
        let referralInfo = fieldValidatingHandler(referralFormRequired, referralInformation)
        let updateCom = {
            ...formCompletionStatus, 
            'Application Information': appInfo.length === 0,
            'Residential Address': resInfo.length === 0,
            'Trading Account': tradeInfo.length === 0,
            'Financial Information': finInfo.length === 0,
            'Banking Information': bank,
            'Proof of Funds': uploadDocuements? !!uploadDocuements.doc_link : false,
            'Wallet Address': wallet,
            'Declaration': declaration,
            'Referral': referralInfo.length === 0,
            'KYC': true
        }
        setFormCompletionStatus(updateCom)
        let isReady = Object.entries(updateCom).every(([key, value]) => {
            if (key === 'Wallet Address') {
                return true; // Always return true for 'Wallet Address', effectively ignoring its actual value
            }
            return value === true;
        });
        
        setReadyToSubmit(isReady)
        console.log('formCompletionStatus', formCompletionStatus);
        console.log('isReady', isReady);
        
    },[applicationInformation, residentialInformation, tradingInformation, financialInformation, bankingInformation, uploadDocuements, declaration, referralInformation, walletAddress])
    // const checkCompleteness = () => {
    //     return formCompletionStatus.values(obj).every(value => value === true);
    // }
    const handleVerification = (curAppStep: number) => {
        console.log('handleVerification curAppStep', curAppStep);
        if(userId) {
            if(curAppStep === 0) {
                //const requiredFields = individualApplicantFormRequired
                handleValidatingForm(individualApplicantFormRequired, applicationInformation, 'applicationInformation')
            }
            else if(curAppStep === 1) {
                //const requiredFields = individualResidentialFormRequired
                handleValidatingForm(individualResidentialFormRequired, residentialInformation, 'residentialInformation')
            }
            else if(curAppStep === 2) {
                const requiredFields = tradingInformation.purpose.includes('Other') ? individualTradingFormRequiredWithOtherPurpose: individualTradingFormRequired
                handleValidatingForm(requiredFields, tradingInformation, 'tradingInformation')
            }
            else if(curAppStep === 3) {
                //const requiredFields = individualFinancialFormRequired
                handleValidatingForm(individualFinancialFormRequired, financialInformation, 'financialInformation')
            }
            else if(curAppStep === 4) {
                //const requiredFields = bankingFormRequired
                handleValidatingMultiForms(bankingFormRequired ,bankingInformation, 'bankingInformation', 'Banking', true)
            }
            else if(curAppStep === 5){
                //TODO: VALIDATE UPLOADED FILE
                handleValidatingRequiredUploads('proofOfFunds')

            }
            else if(curAppStep === 6) {
                //TODO: NO NEED TO VALIDATE WALLET ADDRESS
                handleValidatingMultiForms(walletAddressFormRequired, walletAddress, 'walletAddressInformation', 'Wallet')
            }
            else if(curAppStep === 9) {
                //const requiredFields = referralFormRequired
                handleValidatingForm(referralFormRequired, referralInformation, 'referralInformation')
            }
        }
        else {
            // TODO: userid not present in url, throw error
            console.log('no user id');
            
        }
    }
    const handleValidatingForm = (requiredFields:Record<string, any>, form:Record<string, any>, id:string) => {
        // setOpenValidation(false)
        // setValidationSuccess(false)
        console.log(requiredFields, form)
        let fields = fieldValidatingHandler(requiredFields, form)
        console.log(fields);
        setInvalidInputs(fields)
        let message =  fields.length> 0 ? 
                                    (<div>
                                        <ul>{fields.map(field => <li style={{textAlign: 'left'}}>{field}</li>)}</ul>
                                        <p>Click verify to refresh the missing information</p>
                                    </div>) 
                                    : null
        if(message){
            setAlertId(id)
            setOpenValidation(true)
            setValidationSuccess(false)
            setValidationMessage(message)
            
        }
        else {
            setAlertSuccessId(id+'SUCCESS')
            setOpenValidation(true)
            setValidationSuccess(true)
            
        }
    }
    const handleValidatingMultiForms = (requiredFields:Record<string, any>, form:Record<string, any>[], id:string, formName: string, isBanking: boolean = false) => {
        let forms = isBanking? multiFormsValidatingHandlerForBanks(bankingFormRequired, bankingWithIntBank, bankingInformation):multiFormsValidatingHandler(requiredFields, form)
        console.log(forms);
        setInvalidFormInputs(forms)
        let message =  forms.every(arr => arr.length === 0) ? 
                                    null
                                    : (<>{forms.map((el, index) => el.length > 0 ? (
                                            <div>
                                                <p style={{textAlign: 'left', fontWeight: 'bold'}}>{formName} #{index + 1}</p>
                                                <ul>{el.map((field:string) => <li style={{textAlign: 'left'}}>{field}</li>)}</ul>
                                                
                                            </div>
                                            ): null)}
                                            <p>Click verify to refresh the missing information</p>
                                        </>)
        if(message){
            setAlertId(id)
            setOpenValidation(true)
            setValidationSuccess(false)
            setValidationMessage(message)
            
        }
        else {
            setAlertSuccessId(id+'SUCCESS')
            setOpenValidation(true)
            setValidationSuccess(true)
            
        }
    }
    const handleValidatingRequiredUploads = (id: string) => {
        setOpenValidation(false)
        //uploadDocuements? !!uploadDocuements.doc_link : false
        let message = uploadDocuements.doc_link !== null && uploadDocuements.doc_link.trim() !== '' ? null : 
        <div>
            <ul><li style={{textAlign: 'left'}}>Proof of Funds</li></ul>
            <p>Click verify to refresh the missing information</p>
        </div>
        if(message){
            setAlertId(id)
            setOpenValidation(true)
            setValidationSuccess(false)
            setValidationMessage(message)
            
        }
        else {
            setAlertSuccessId(id+'SUCCESS')
            setOpenValidation(true)
            setValidationSuccess(true)
            
        }
    }
    const handleSubmitApplication = async() => {
        setSubmissionLoading(true)
        await handlePagesInfoSave(9, false)
        let submitUser = await getUserInfo(Number(userId))
        let res = await submitIndividualApplication(Number(userId), submitUser)
        console.log('handleSubmitApplication', res);
        setSubmissionLoading(false)
        if(res && res.status === 201) {
            // successful submitted navigate back to login or stay
            setSubmissionOpen(true)
            setSubmissionSuccess(true)

        }
        else {
            // failure alert
            setSubmissionOpen(true)
            setSubmissionSuccess(false)
        }
    }
    const quitButton = (
        <div className={s.buttonContainer}>
            <Button variant="outline" size="sm" className={s.button} onClick={handleLogOut}>Exit Application</Button>
        </div>  
    )
    if(user){
    return (
        <div className={s.container}>
            <div className={s.leftContainer}>
                {theme === 'dark' ?
                    <img className={s.headerImage} src={require('../../assets/SDM-dark.png')} alt="SDM" height={80} width='auto'/>
                    :<img className={s.headerImage} src={require('../../assets/SDM-light.png')} alt="SDM" height={80} width='auto'/>
                }
                {isMobile ? null:<div className={s.stepperContainer}>
                    {Object.keys(individualSteps).map((step, index) => (
                        <div 
                            key={index}
                            className={curStep === index ? cn(s.stepper, s.activeStepper):s.stepper}
                            onClick={() => handleStepperClick(step, index)}
                        >
                            <div className={cn(s.stepIcon)} style={{color: 'white', width: '25%'}}>{ 
                                formCompletionStatus[step] ? 
                                (index === 6) ? 
                                    curStep === index ? <CheckCircleIcon color='success'/> : <CheckCircleOutlineIcon color='success'/>
                                :(index === 8) ? 
                                    curStep === index ? <ErrorIcon sx={{color: 'grey'}}/> : <ErrorOutlineIcon sx={{color: 'grey'}}/> 
                                :curStep === index ? <CheckCircleIcon color='success'/> : <CheckCircleOutlineIcon color='success'/> 
                                :curStep === index ? (index === 6) ? <ErrorIcon sx={{color: 'grey'}}/> : <ErrorIcon sx={{color: 'black'}}/>
                                :(index === 6)? <ErrorOutlineIcon sx={{color: 'grey'}}/>:<ErrorOutlineIcon color='warning'/>}
                            </div>
                            <div className={theme === 'dark' ? 
                                curStep === index ? cn(s.stepName, s.stepDark, s.activeStepper):cn(s.stepName, s.stepDark, s.stepper)
                                :curStep === index ? cn(s.stepName, s.stepLight, s.activeStepper):cn(s.stepName, s.stepLight, s.stepper)}>
                                {step}
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
            <div className={s.rightContainer}>
                <div className={s.logout}>
                    
                    <Button variant="monochrome" size="lg" onClick={handleLogOut}>
                        Log out
                    </Button>
                    <ThemeSwitchToggle />
                    {/* <IconButton sx={theme === 'dark' ? { ml: 1, color: 'white'} : { ml: 1, color: 'black'}} onClick={toggleTheme} color="inherit">
                        {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton> */}
                    {/* <div className={s.checkboxSection}> */}
                    
                    {/* <p className={theme === 'dark'? s.dark : s.light}>{theme === 'dark' ? 'Dark Mode' : 'Light Mode'}</p> */}
                </div>
                <div className={s.headerSection}>
                    <div className={theme === 'dark' ? cn(s.darkFont, s.header) : cn(s.lightFont, s.header)}>{stepsArray[curStep]}</div>
                    <div className={s.buttons}>
                        {curStep === 8 || curStep === 7 ? null : <Button  className={s.button} size="lg" onClick={async() => {await handlePagesInfoSave(curStep, true); handleVerification(curStep)}} disabled={submissionLoading || pageLoading}>
                            Save and Verify
                        </Button>}
                        {curStep === 0 ? null :
                        <Button className={s.button} size="lg" variant="brand" onClick={onPreviousPage} disabled={submissionLoading || pageLoading}>
                            Previous
                        </Button>}
                        {curStep === 9 ? <Button variant="brand" className={s.button} size="lg" onClick={handleSubmitApplication} disabled={submissionLoading || !readyToSubmit || pageLoading}>
                            {submissionLoading? 'Submitting ...':'Submit'}
                        </Button> : <Button variant="brand" className={s.button} size="lg" onClick={onNextPage} disabled={submissionLoading || pageLoading}>
                            Next
                        </Button>}
                        
                        
                    </div>
                </div>
                {curStep === 0 && <ApplicationInformation applicationInformation={applicationInformation} setApplicationInformation={setApplicationInformation} setFormCompletionStatus={setFormCompletionStatus} invalidInputFields={invalidInputs}/>}
                {curStep === 1 && <ResidentialInformation residentialInformation={residentialInformation} setResidentialInformation={setResidentialInformation} setFormCompletionStatus={setFormCompletionStatus} invalidInputFields={invalidInputs}/>}
                {curStep === 2 && <TradingAccount tradingInformation={tradingInformation} setTradingInformation={setTradingInformation} setFormCompletionStatus={setFormCompletionStatus} invalidInputFields={invalidInputs}/>}
                {curStep === 3 && <FinancialInformation financialInformation={financialInformation} setFinancialInformation={setFinancialInformation} setFormCompletionStatus={setFormCompletionStatus} invalidInputFields={invalidInputs}/>}
                {curStep === 4 && <BankingInformation bankingInformation={bankingInformation} setBankingInformation={setBankingInformation} setFormCompletionStatus={setFormCompletionStatus} invalidInputFields={invalidFormInputs}/>}
                {curStep === 5 && <UploadDocuments uploadDocuements={uploadDocuements} setUploadDocuments={setUploadDocuments} userPublicFolderLink={user.user_registration?.public_folder_url} userId={userId || ''} setFormCompletionStatus={setFormCompletionStatus}/>}
                {curStep === 6 && <WalletAddress walletAddress={walletAddress} setWalletAddress={setWalletAddress} />}
                {curStep === 7 && <Declaration declaration={declaration} setDeclaration={setDeclaration} setFormCompletionStatus={setFormCompletionStatus}/>}
                {curStep === 8 && <KYC userEmail={user.user_registration?.primary_contact_email}/>}
                {curStep === 9 && <Referral referralInformation={referralInformation} setReferralInformation={setReferralInformation} setFormCompletionStatus={setFormCompletionStatus} invalidInputFields={invalidInputs} />}
                <div className={cn(s.footerSection, { [s.alignRight]: curStep === 0 })}>
                    <Button variant="brand" className={curStep === 0 ? cn(s.button, s.hide):s.button} size="lg" onClick={onPreviousPage} disabled={submissionLoading}>
                        Previous
                    </Button>
                    {curStep === 9 ? <Button variant="brand" className={s.button} size="lg" onClick={handleSubmitApplication} disabled={submissionLoading || !readyToSubmit || pageLoading}>
                        {submissionLoading? 'Submitting ...':'Submit'}
                    </Button> : <Button variant="brand" className={s.button} size="lg" onClick={onNextPage} disabled={submissionLoading || pageLoading}>
                        Next
                    </Button>}
                </div>
            </div>
            <CornerToaster id={'missing'} header={'Missing Required Information'} message={validationMessage} error={true} show={openValidation && !validationSuccess} success={false} closeToast={() => setOpenValidation(false)} position={"bottom-left"}/>
            <CornerToaster id={'filled'} header={stepsArray[curStep]} message={'All information has been successfully saved.'} error={false} show={openValidation && validationSuccess} success={true} closeToast={() => setOpenValidation(false)} position={"bottom-left"}/>
            <TopCornerToaster id={'saveSuccess'} header={savedPage + ' saved'} error={false} show={informationSaveOpen && saveSuccess} success={true} closeToast={() => setInformationSaveOpen(false)}/>
            <TopCornerToaster id={'SaveFailed'} header={savedPage + ' failed to save'} message={'Something went wroing, please try again later.'} error={true} show={informationSaveOpen && !saveSuccess} success={false} closeToast={() => setInformationSaveOpen(false)} />
            <Modal open={submissionOpen && submissionSuccess} setOpen={setSubmissionOpen} isSuccess={true} isFailed={false} header={'Application Details Enrolled Successfully.'} message={'We will get back to you soon.'} action={quitButton}/>
            <Modal open={submissionOpen && !submissionSuccess} setOpen={setSubmissionOpen} isSuccess={false} isFailed={true} header={'Network error.'} message={'Please try to submit your application again'}/>
        </div>
    );}
    else {
        return (<div className={s.container}></div>)
    }
}

export default Individual;