import React from 'react';
import { UBOVerificationData } from '../../../components/Types';
import s from './forms.module.scss';
import cn from 'classnames';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Checkbox, TextField, DatePicker, AutoCompleteSelect, Button } from '../../../components/UILib';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
import countries from '../../../constants/countries';
import { useTheme } from '../../../contexts/ThemeContext';
interface UBOVerificationProps {
    uboVerification: UBOVerificationData[];
    setUboVerification: React.Dispatch<React.SetStateAction<UBOVerificationData[]>>;
    invalidInputFields: any[];
}

let countryList = countries.map(country => {return {value: country.name, label: country.name}})

function UBOVerification({uboVerification, setUboVerification, invalidInputFields}: UBOVerificationProps) {
    const { theme } = useTheme();
    const handleSelectionValueChange = (selection: AutoCompleteSelectDataType, name: string, index: number) => {
        console.log('handleSelectionValueChange', name + " " + selection.value);
        const updatedAccounts = uboVerification.map((account, idx) => {
            if (idx === index) {
                return {...account, [name]: selection.value };
            }
            return account;
        });
        setUboVerification(updatedAccounts)
    }
    const handleInputValueChange = (value: string, name: string, index: number) => {
        console.log('handleInputValueChange', name + " " + value);
        const updatedAccounts = uboVerification.map((account, idx) => {
            if (idx === index) {
                return {...account, [name]: value };
            }
            return account;
        });
        setUboVerification(updatedAccounts)
    }
    const handleDOBChange = (value: any, index: number) => {
        console.log('handleDOBChange', value);
        const updatedAccounts = uboVerification.map((account, idx) => {
            if (idx === index) {
                return {...account, date_of_birth: value };
            }
            return account;
        });
        setUboVerification(updatedAccounts)
    }
    const handleAddNewUBO = () => {
        let newUBO : UBOVerificationData = {
            title: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            date_of_birth: '',
            email: '',
            country: '',
            ownership: '',
        }
        let updatedAccounts = [...uboVerification]
        updatedAccounts.push(newUBO)
        setUboVerification(updatedAccounts);
    }
    const removeNextUBO = (index: number) => {
        const updatedAccounts = [...uboVerification]
        if (index + 1 < updatedAccounts.length) {
            // Remove 1 element at position index + 1
            updatedAccounts.splice(index + 1, 1);
        }
        setUboVerification(updatedAccounts);
    }
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={theme === 'dark' ? cn(s.subHeader, s.darkFont) : cn(s.subHeader, s.lightFont)}>ULTIMATE BENEFICIAL OWNERS</div>
                </div>
                <div className={cn(s.row, s.addMarginBottom)}>
                    <div className={theme === 'dark' ? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>Please fill out the following information for all shareholders owning 10% or more of the entity</div>
                </div>
                {uboVerification.map((ubo, index) =>
                (<>
                    <div className={s.row}>
                        <div className={theme === 'dark' ? cn(s.thirdHeader, s.darkFont) : cn(s.thirdHeader, s.lightFont)}>UBO Verification #{index+1}</div>
                    </div>
                    <div className={s.row}>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Title/Occupation</p>
                            <TextField
                                name="title"
                                value={ubo.title}
                                onChange={(value, name) => handleInputValueChange(value, name, index)}
                                variant="xs"
                                required={false}
                            />
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.inputSection}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>First Name <sup>*</sup></p>
                            <TextField
                                name="first_name"
                                value={ubo.first_name}
                                onChange={(value, name) => handleInputValueChange(value, name, index)}
                                variant="xs"
                                externalError={invalidInputFields[index] && invalidInputFields[index].includes("First Name")}
                                error={invalidInputFields[index] && invalidInputFields[index].includes("First Name") ? 'Please enter first name.' : undefined}
                            />
                        </div>
                        <div className={s.inputSection}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Middle Name(s) (if applicable)</p>
                            <TextField
                                name="middle_name"
                                value={ubo.middle_name}
                                onChange={(value, name) => handleInputValueChange(value, name, index)}
                                variant="xs"
                                required={false}
                            />
                        </div>
                        <div className={s.inputSection}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Last Name <sup>*</sup></p>
                            <TextField
                                name="last_name"
                                value={ubo.last_name}
                                onChange={(value, name) => handleInputValueChange(value, name, index)}
                                variant="xs"
                                externalError={invalidInputFields[index] && invalidInputFields[index].includes("Last Name")}
                                error={invalidInputFields[index] && invalidInputFields[index].includes("Last Name") ? 'Please enter last name.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Date of Birth<sup>*</sup></p>
                            <DatePicker width={'100%'} onChange={(date) => handleDOBChange(date, index)} value={ubo.date_of_birth} error={invalidInputFields[index] && invalidInputFields[index].includes("Date of Birth")} errorText={invalidInputFields[index] && invalidInputFields[index].includes("Date of Birth")? 'Please provide date of birth.' : undefined}/>
                        </div>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Email<sup>*</sup></p>
                            <TextField
                                name="email"
                                value={ubo.email}
                                onChange={(value, name) => handleInputValueChange(value, name, index)}
                                variant="xs"
                                type='email'
                                externalError={invalidInputFields[index] && invalidInputFields[index].includes("Email")}
                                error={invalidInputFields[index] && invalidInputFields[index].includes("Email") ? 'Please enter email.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Country/Region <sup>*</sup></p>
                            <AutoCompleteSelect
                                optionList={countryList}
                                value={{value: ubo.country, label: ubo.country}}
                                onChange={(e, value) => handleSelectionValueChange(value, 'country', index)}
                                errorText={invalidInputFields[index] && invalidInputFields[index].includes("Country/Region")? 'Please select a valid country/region' : undefined}
                            />
                        </div>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Ownership Percentage <sup>*</sup></p>
                            <TextField
                                name="ownership"
                                value={ubo.ownership}
                                onChange={(value, name) => handleInputValueChange(value, name, index)}
                                variant="xs"
                                type='number'
                                rightElement={<span>%</span>}
                                rightElementClassName={s.rightElement}
                                externalError={invalidInputFields[index] && invalidInputFields[index].includes("Ownership Percentage")}
                                error={invalidInputFields[index] && invalidInputFields[index].includes("Ownership Percentage") ? 'Please enter a valid number.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={cn(s.row, s.addMargin)}>
                        <Divider className={theme === 'dark'? s.sectionDividerDark : s.sectionDividerLight }/>
                    </div>
                    <div className={cn(s.addMoreMargin, s.toRight)}>
                        {uboVerification[index + 1] ? 
                        <Button
                            leftElement={<RemoveIcon />}
                            danger
                            className={s.button}
                            size="xs"
                            onClick={() => removeNextUBO(index)}
                        >
                            Remove Ultimate Beneficial Owner {index+2}
                        </Button>
                        :<Button
                            leftElement={<AddIcon />}
                            className={s.button}
                            size="xs"
                            onClick={handleAddNewUBO}
                        >
                            Add Another Ultimate Beneficial Owner
                        </Button>}
                    </div>
                </>))}
            </div>
        </div>
    );
}

export default UBOVerification;