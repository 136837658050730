import React, { useEffect, useState } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { Button } from '../../../components/UILib';
import { useTheme } from '../../../contexts/ThemeContext';
import { getKYCAccessToken } from '../../../api/applicantion';
import s from './forms.module.scss';
interface KYCComponentProps {
    userEmail: string;
}
function KYC({userEmail}: KYCComponentProps) {
    const { theme } = useTheme();
    const [accessToken, setAccessToken] = useState<string>('');
    const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false)
    useEffect(() => {
        if(userEmail) {
            getAccessToken()
        }
    }, [userEmail])

    const getAccessToken =async() => {
        let token = await getKYCAccessToken(userEmail)
        token = token.token
        setAccessToken(token);
        setShowRefreshButton(false)
    }
    const accessTokenExpirationHandler = async(message: any) => {
        console.log('accessTokenExpirationHandler', message);
        setShowRefreshButton(true)
    }
    return (
        <div className={s.rightContainer}>
            {showRefreshButton ? <div className={s.formContainer}>
                <div className={s.rowToCenter}>
                    <Button variant="outline" className={s.button} size="lg" onClick={getAccessToken}>Refresh KYC</Button>
                </div>
            </div> : null}
            {accessToken && <SumsubWebSdk
                accessToken={accessToken}
                expirationHandler={accessTokenExpirationHandler}
                config={{
                    lang: 'en',
                    theme: theme
                }}
                // options={options}
                // onMessage={messageHandler}
                // onError={errorHandler}
            />}
            
        </div>
    );
}

export default KYC;