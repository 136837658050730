import React, {useState, useEffect} from 'react';
import cn from 'classnames';

import icons from '../../../icons';

import s from './Icon.module.scss';

export type IconNamesType = keyof typeof icons;

export interface IconProps extends React.HTMLAttributes<SVGElement> {
    className?: string;
    name: IconNamesType;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const Icon: React.FC<IconProps> = ({ className, name, onClick, ...other }) => {
    const [SvgIcon, setSvgIcon] = useState<React.FC<React.SVGProps<SVGSVGElement>> | null>(null);
    useEffect(() => {
        const loadIcon = async () => {
            if (!name) return;
            try {
                // Assuming your setup allows for importing SVGs as React components
                const { ReactComponent } = await icons[name];
                setSvgIcon(ReactComponent); // Use the imported React component
            } catch (error) {
                console.error(`Failed to load the icon: ${name}`, error);
            }
        };
    
        loadIcon();
    }, [name]);
    
    if (!SvgIcon) return null;

    return <SvgIcon />;
};

export default Icon;
