// ToastNotification.js
import React from 'react';
import { toast, Toaster, ToastPosition } from 'react-hot-toast';
import cn from 'classnames';
import s from './CornerToaster.module.scss';
import { useTheme } from '../../../contexts/ThemeContext';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export interface CornerToasterProps {
    message?: React.ReactNode;
    error: boolean;
    success: boolean;
    show: boolean;
    closeToast: () => void;
    id: string;
    header?:string;
    position?: ToastPosition
}
const CornerToaster : React.FC<CornerToasterProps> = ({ 
    message,
    error = false,
    success = false,
    show,
    closeToast,
    id,
    header,
    position = 'bottom-left'
}) => {
    const { theme } = useTheme();
    console.log(theme);
    
    // Function to show toast
    const showToast = (message : React.ReactNode) => {
        toast((t) => (
            <div className={s.toasterContainer}>
                <div className={s.headerContainer}>
                    <div className={s.header}>
                        {error? <><ErrorOutlineTwoToneIcon color='error'/><span>{header? header : 'Error'}</span></> : success? <><CheckCircleOutlinedIcon color='success'/><span style={{textAlign: 'left'}}>{header? header: 'Success'}</span></> : null}
                    </div>
                    <div className={theme === 'dark' ? cn(s.closeButton, s.dark) : cn(s.closeButton, s.light)} onClick={() => handleCloseToaster()}>x</div>
                </div>
                
                {message? message : null}
            </div>
            ), 
            {   
                id: id,
                duration: 5000,
                // onClose: () => closeToast()
                // Add your styling here
                style: theme === 'dark' ? {
                    border: '1px solid #fff',
                    padding: '5px',
                    color: '#fff',
                    background: '#252525',
                    
                }:{
                    border: '1px solid #000',
                    padding: '5px',
                    color: '#000',
                    background: '#eeecf1',
                },
                // iconTheme: {
                //     primary: '#713200',
                //     secondary: '#FFFAEE',
                // },
            }
        );
    };
    const handleCloseToaster = () => {
        closeToast()
        toast.remove(id);
    }
    // Automatically show toast when component is rendered
    React.useEffect(() => {
        if(show) {
            showToast(message)
        }
        else{
            handleCloseToaster()
            //toast.remove(id);
        }
    }, [show, id, header, message]);

    return <Toaster position={position || 'bottom-left'} />;
};

export default CornerToaster;
