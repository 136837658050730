import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTheme } from '../../../contexts/ThemeContext';
import moment, { Moment } from 'moment';

import cn from 'classnames';
import s from './Dropdown.module.scss';

export interface DatePickerProps {
    onChange: (date: Moment | null) => void;
    value: string;
    // size?: 'small' | 'medium';
    width?: string | number;
    errorText?: string | undefined;
    error?: boolean;
    // // containerStyle?: any;
    // // radioContainerStyle?: any;
}

const CustomizedDatePicker: React.FC<DatePickerProps> = ({
    value = moment().toISOString(),
    onChange,
    width = '100%',
    errorText,
    error = false,
}: DatePickerProps) => {
    const { theme } = useTheme();
    const dateFormat = 'YYYY-MM-DD';
    return (
        // <CustomDateCalendar>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    value={moment(value, dateFormat)}
                    onChange={onChange}
                    slotProps={{
                        layout: {
                            sx: {
                                '.MuiDateCalendar-root': theme === 'dark' ? {
                                    color: 'white',
                                    borderRadius: 0,
                                    borderWidth: 1,
                                    borderColor: '#4d4d4d',
                                    border: '1px solid',
                                    backgroundColor: '#202020',
                                } : {
                                    color: 'black',
                                    borderRadius: 0,
                                    borderWidth: 1,
                                    borderColor: '#4d4d4d',
                                    border: '1px solid',
                                    backgroundColor: '#FAF9F6',
                                },
                                '.MuiPickersCalendarHeader-switchViewButton': {
                                    color: '#4d4d4d',
                                },
                                '.MuiSvgIcon-root': {
                                    color: '#4d4d4d',
                                },
                                '.MuiDayCalendar-weekDayLabel': theme === 'dark'? {
                                    color: 'white'
                                }: {
                                    color: 'black'
                                },
                                '.MuiPickersDay-root': theme === 'dark'? {
                                    color: 'white'
                                } : {
                                    color: 'black'
                                },
                                '.MuiPickersDay-root.Mui-selected': {
                                    backgroundColor: '#EBAF00',
                                    color: 'black'
                                },
                                '.MuiPickersDay-root.MuiPickersDay-today': theme === 'dark'? {
                                    borderColor: 'white'
                                } : {
                                    borderColor: 'black'
                                }
                            }
                        },
                        textField: {
                            size: 'small',
                            helperText: errorText,
                            error: error,
                            sx: {
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderWidth: '1px',
                                            borderColor: '#4d4d4d',
                                            borderRadius: '5px',
                                        },
                                        '&:hover fieldset': {
                                            borderWidth: '2px',
                                            borderColor: '#898989', 
                                            borderRadius: '5px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderWidth: '2px',
                                            borderColor: '#0676ED', 
                                            borderRadius: '5px',
                                        },
                                        '&.Mui-error fieldset': {
                                            borderWidth: '2px',
                                            borderColor: '#f33', 
                                            borderRadius: '5px',
                                        },
                                    },
                                    width: width, // Adjust as needed
                                    background: 'transparent', // Example: change background color
                                    // Change icon color
                                    '&.MuiFormHelperText-root.Mui-error': {
                                        color: '#f33', /* Change to any color you want */
                                        fontFamily: "'Avenir', sans-serif" /* Change to your desired font */
                                        //font-size: 14px; /* Adjust size as needed */
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#4d4d4d', // Example: change icon color
                                    },
                                    // Change font and font color
                                    '& .MuiInputBase-input': theme === 'dark' ? {
                                        color: 'white', // Example: change font color
                                        fontFamily: "'Avenir', sans-serif", // Example: change font family
                                    } : {
                                        color: 'black', // Example: change font color
                                        fontFamily: "'Avenir', sans-serif", // Example: change font family
                                    },
                                }
                        }
                    }}
                />
            </LocalizationProvider>
        // </CustomDateCalendar>
    )
}
export default CustomizedDatePicker;
