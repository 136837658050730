import React from 'react';
import s from './LoginPage.module.scss';
import ApplicationSteps from './ApplicationSteps';
import LoginSection from './LoginSection';
import ThemeControllerSection from './ThemeControllerSection';

function LoginPage() {
    return (
        <div className={s.outer}>
            <ApplicationSteps/>
            <LoginSection/>
            <ThemeControllerSection />
        </div>
    );
}

export default LoginPage;