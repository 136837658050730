import React from 'react';
import { DirectorData } from '../../../components/Types';
import s from './forms.module.scss';
import cn from 'classnames';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '../../../contexts/ThemeContext';
import { Checkbox, TextField, DatePicker, AutoCompleteSelect, Button } from '../../../components/UILib';
interface DirectorFormProps {
    directors: DirectorData[]; 
    setDirectors: React.Dispatch<React.SetStateAction<DirectorData[]>>;
    director: DirectorData;
    index: number;
    invalidInputFields: string[];
}
function DirectorForm({directors, setDirectors, director, index, invalidInputFields}: DirectorFormProps) {
    const { theme } = useTheme();
    const handleInputValueChange = (value: string, name: string) => {
        console.log('handleInputValueChange', name + " " + value);
        const updatedAccounts = directors.map((account, idx) => {
            if (idx === index) {
                return {...account, [name]: value };
            }
            return account;
        });
        setDirectors(updatedAccounts)
    }
    const addNextDirector = () => {
        let newDirector : DirectorData = {
            title: '',
            full_name: '',
            email: '',
            contact_number: '',
            nationality: '',
        }
        let updatedAccounts = [...directors]
        updatedAccounts.push(newDirector)
        setDirectors(updatedAccounts);
    }
    const removeNextDirector = () => {
        const updatedAccounts = [...directors]
        if (index + 1 < updatedAccounts.length) {
            // Remove 1 element at position index + 1
            updatedAccounts.splice(index + 1, 1);
        }
        setDirectors(updatedAccounts);
    }
    return (
        <>
            <div className={s.row}>
                <div className={theme === 'dark' ? cn(s.thirdHeader, s.darkFont) : cn(s.thirdHeader, s.lightFont)}>Director #{index+1}</div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Director’s Title/Occupation</p>
                    <TextField
                        name="title"
                        value={director.title}
                        onChange={handleInputValueChange}
                        variant="xs"
                        required={false}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Director’s Full Legal Name <sup>*</sup></p>
                    <TextField
                        name="full_name"
                        value={director.full_name}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields && invalidInputFields.includes("Full Legal Name")}
                        error={invalidInputFields && invalidInputFields.includes("Full Legal Name") ? 'Please enter full legal name.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Director’s Email Address <sup>*</sup></p>
                    <TextField
                        name="email"
                        value={director.email}
                        onChange={handleInputValueChange}
                        variant="xs"
                        type='email'
                        externalError={invalidInputFields && invalidInputFields.includes("Email Address")}
                        error={invalidInputFields && invalidInputFields.includes("Email Address") ? 'Please enter a valid email address.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Contact Number <sup>*</sup></p>
                    <TextField
                        name="contact_number"
                        value={director.contact_number}
                        onChange={handleInputValueChange}
                        variant="xs"
                        type='number'
                        externalError={invalidInputFields && invalidInputFields.includes("Contact Number")}
                        error={invalidInputFields && invalidInputFields.includes("Contact Number") ? 'Please enter a valid contact number.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Nationality/Jurisdiction <sup>*</sup></p>
                    <TextField
                        name="nationality"
                        value={director.nationality}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields && invalidInputFields.includes("Nationality/Jurisdiction")}
                        error={invalidInputFields && invalidInputFields.includes("Nationality/Jurisdiction") ? 'Please enter nationality/jurisdiction.' : undefined}
                    />
                </div>
            </div>
            <div className={cn(s.row, s.addMargin)}>
                <Divider className={theme === 'dark'? s.sectionDividerDark : s.sectionDividerLight }/>
            </div>
            <div className={cn(s.addMoreMargin, s.toRight)}>
                {directors[index + 1] ? 
                <Button
                    leftElement={<RemoveIcon />}
                    danger
                    className={s.button}
                    size="xs"
                    onClick={() => removeNextDirector()}
                >
                    Remove Director {index+2}
                </Button>
                :<Button
                    leftElement={<AddIcon />}
                    className={s.button}
                    size="xs"
                    onClick={addNextDirector}
                >
                    Add Another Director
                </Button>}
            </div>
        </>
    );
}

export default DirectorForm;