import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { verifyOTP } from '../../api/applicantion';
import { UserOTPVerificationDataType } from '../../components/Types/UserAuthRequestType';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/UILib';
import s from './VerifyOTP.module.scss';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { useTheme } from '../../contexts/ThemeContext';
function GradientCircularProgress() {
    return (
        <React.Fragment>
            <svg width={0} height={0}>
            <defs>
                <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#EBAF00" />
                <stop offset="100%" stopColor="#ffe699" />
                </linearGradient>
            </defs>
            </svg>
            <CircularProgress size={'10%'} thickness={1.5} sx={{ 'svg circle': { stroke: 'url(#my_gradient)' }}}  />
        </React.Fragment>
    );
}
function VerifyOtp() {
    const navigate = useNavigate();
    const {theme} = useTheme();
    // get params from query
    const location = useLocation();
    const [loading, setLoading] = useState(true)
    const queryParams = new URLSearchParams(location.search);
    const otp = queryParams.get('otp');
    const user = queryParams.get('user');
    console.log('otp', otp);
    console.log('user', user);
    useEffect(() => {
        if(otp && user){
            verifyOTPCode(otp, Number(user))
        }
    },[otp, user])
    const handleBackToLogin = () => {
        navigate('/');
    }
    // verify the otp code 
    const verifyOTPCode = async(otp: string, user: number) => {
        let userOTP: UserOTPVerificationDataType= {
            otp: otp,
            userId: user
        }
        let res = await verifyOTP(userOTP)
        console.log(res);
        if(res){
            if(res.user_registration){
                if(!res.user_registration.application_type){
                    navigate(`/boarding?userId=${user}`, { state: { loginEmail: res.user_name }});
                }
                else {
                    // redirect to application page depends on the application type
                    navigate(`/${res.user_registration.application_type}?userId=${user}`, { state : { user: res }});
                }
            }
            else {
                navigate(`/boarding?userId=${user}`, { state: { loginEmail: res.user_name }});
            }
        }
        else {
            return
        }
        setLoading(false)
    }
    if(loading) {
        return (
            <div className={s.outer}>
                <Stack spacing={2} sx={{ flexGrow: 1, alignItems: 'center' }}>
                    <GradientCircularProgress />
                </Stack>
            </div>
        )
    }
    else return (
        <div className={s.outer}>
            <div className={theme === 'dark'? cn(s.dark, s.message) : cn(s.light, s.message)}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle className={cn(s.path, s.circle)} fill="none" stroke="#f33" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                <line className={cn(s.path, s.line)} fill="none" stroke="#f33" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                <line className={cn(s.path, s.line)} fill="none" stroke="#f33" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
            </svg>
            <p>Can't retreive your information right now, your link might be expired, please try to login again later </p>
            <Button
                className={s.button}
                size="lg"
                variant='brand'
                onClick={handleBackToLogin}
            >
                Back To Login Page
            </Button>
        </div>
        </div>
        
    );
}

export default VerifyOtp;