import React from 'react';
import s from './Boarding.module.scss'
import BoardingSteps from './BoardingSteps';
import BoardingForm from './BoardingForm';
import ThemeControllerSection from './ThemeControllerSection';
function Boarding() {
    return (
        <div className={s.outer}>
            <BoardingSteps/>
            <BoardingForm/>
            <ThemeControllerSection />
        </div>
    );
}

export default Boarding;