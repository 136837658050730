export interface entityDocumentType {
    [key: string]: Record<string, boolean>
}
export const entityDocumentTypes: entityDocumentType = {
    'Corporation' : {
        'Orignal Certificate/Articles of Incorporation': true, 
        'Signed & Dated Shareholders Register - Document outlining the shareholders and their ownership percentage' : true,
        'Company Bank Statement (Issued within the past 90 days)' : true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)' : true,
        'Board Resolution Authorizing an Account Opening with SDM' : true,
        'Additional file (optional)' : false,
    },
    'Limited Liability Company (LLC)': {
        'Orignal Certificate/Articles of Organization': true, 
        'Signed & Dated LLC Operating Agreement': true,
        'Company Bank Statement (Issued within the past 90 days)': true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)': true,
        'Board Resolution Authorizing an Account Opening with SDM': true,
        'Additional file (optional)': false,
    },
    'Limited Partnership (LP)': {
        'Original Certificate of Limited Partnership': true,
        'Signed & Dated Limited Partnership Agreement': true,
        'Company Bank Statement (Issued within the past 90 days)': true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)': true,
        'Board Resolution Authorizing an Account Opening with SDM': true,
        'Additional file (optional)': false,
    },
    'Limited Liability Partnership (LLP)': {
        'Original Certificate of Formation/Incorporation': true,
        'Signed & Dated Limited Liability Partnership Agreement': true,
        'Entity Bank Statement (Issued within the past 90 days)': true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)': true,
        'Board Resolution Authorizing an Account Opening with SDM': true,
        'Additional file (optional)': false,
    },
    'Limited Liability Limited Partnership (LLLP)': {
        'Original Certificate of Limited Liability Limited Partnership': true,
        'Signed & Dated Partnership Agreement/LLLP Agreement': true,
        'Entity Bank Statement (Issued within the past 90 days)': true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)': true,
        'Board Resolution Authorizing an Account Opening with SDM': true,
        'Additional file (optional)': false,
    },
    'Trust': {
        'Original Certificate of Trust': false, //TODO: NOT REQUIRED
        'Signed & Dated Trust Deed': true,
        'Entity Bank Statement (Issued within the past 90 days)': true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)': true,
        'Additional file (optional)': false,
    },
    'General Partnership (GP)': {
        'Original Registration Certificate': true,
        'Signed & Dated Partnership Agreement': true,
        'Entity Bank Statement (Issued within the past 90 days)': true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)': true,
        'Additional file (optional)': false,
    },
    'Family Limited Partnership (FLP)': {
        'Original Certificate of Limited Partnership': true,
        'Signed & Dated Family Limited Partnership Agreement': true,
        'Entity Bank Statement (Issued within the past 90 days)': true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)': true,
        'Additional file (optional)': false,
    },
    'Sole Proprietorship': {
        'Original Registration Certificate': true,
        'Bank Statements for the Past Three Months': true,
        'Proof of Address (Utility Bill/Bank Statement issued within the past 90 days)': true,
        'Business Model Overview': true,
        'Proof of Work (Work Contracts/Agreements)': true,
        'EIN Confirmation Letter': true,
        'Additional file (optional)': false,
    },
}
export const flowOfFundsFromFiatToCrypto = {'Bank Statement showing the initial trade amount (issued within the past 90 days)':true}
export const moneyServiceBusiness = {
    'Money Services Business Registration': true, 
    'AML/CTF Policy Document': true
}