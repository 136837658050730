const occupations = [
    {'label': 'Fintrac Occupations', 'value': 'Fintrac Occupations'},
    {'label': 'Accountants and Auditors', 'value': 'Accountants and Auditors'},
    {'label': 'Actors and Comedians', 'value': 'Actors and Comedians'},
    {'label': 'Admin Assistants', 'value': 'Admin Assistants'},
    {'label': 'Agricultural Specialists', 'value': 'Agricultural Specialists'},
    {'label': 'Air Pilot', 'value': 'Air Pilot'},
    {'label': 'Architecture Manager', 'value': 'Architecture Manager'},
    {'label': 'Authors and Writers', 'value': 'Authors and Writers'},
    {'label': 'Banking Manager', 'value': 'Banking Manager'},
    {'label': 'Business Analyst', 'value': 'Business Analyst'},
    {'label': 'Business Analyst (IT)', 'value': 'Business Analyst (IT)'},
    {'label': 'Business Management Consultant',
    'value': 'Business Management Consultant'},
    {'label': 'Cable Television Service and Maintenance Technician',
    'value': 'Cable Television Service and Maintenance Technician'},
    {'label': 'Correspondence, Publication and Regulatory Clerk',
    'value': 'Correspondence, Publication and Regulatory Clerk'},
    {'label': 'Customer Services Representative Contact Centre',
    'value': 'Customer Services Representative Contact Centre'},
    {'label': 'Dentist', 'value': 'Dentist'},
    {'label': 'Director', 'value': 'Director'},
    {'label': 'Electrician', 'value': 'Electrician'},
    {'label': 'Electronics Assembler', 'value': 'Electronics Assembler'},
    {'label': 'Engineering Manager', 'value': 'Engineering Manager'},
    {'label': 'Executive Business and Finance',
    'value': 'Executive Business and Finance'},
    {'label': 'Finance Manager', 'value': 'Finance Manager'},
    {'label': 'Financial Analyst', 'value': 'Financial Analyst'},
    {'label': 'Fishermen/Women', 'value': 'Fishermen/Women'},
    {'label': 'Fitness Instructor', 'value': 'Fitness Instructor'},
    {'label': 'Food and Beverage Server', 'value': 'Food and Beverage Server'},
    {'label': 'Food Preparer', 'value': 'Food Preparer'},
    {'label': 'General Contractor', 'value': 'General Contractor'},
    {'label': 'General Farm Worker', 'value': 'General Farm Worker'},
    {'label': 'General Office Worker', 'value': 'General Office Worker'},
    {'label': 'Graphic Designers and Illustrators',
    'value': 'Graphic Designers and Illustrators'},
    {'label': 'Housekeeper', 'value': 'Housekeeper'},
    {'label': 'Human Resources Manager', 'value': 'Human Resources Manager'},
    {'label': 'Human Resources Professional',
    'value': 'Human Resources Professional'},
    {'label': 'Insurance Broker', 'value': 'Insurance Broker'},
    {'label': 'Investment Broker', 'value': 'Investment Broker'},
    {'label': 'Janitors and Caretakers', 'value': 'Janitors and Caretakers'},
    {'label': 'Labourer Food and Beverage Processing',
    'value': 'Labourer Food and Beverage Processing'},
    {'label': 'Landscaper', 'value': 'Landscaper'},
    {'label': 'Lawyer', 'value': 'Lawyer'},
    {'label': 'Legislators and Politicians',
    'value': 'Legislators and Politicians'},
    {'label': 'Machining Tool Operators', 'value': 'Machining Tool Operators'},
    {'label': 'Machinists', 'value': 'Machinists'},
    {'label': 'Manager Public Administration',
    'value': 'Manager Public Administration'},
    {'label': 'Manager Social Services', 'value': 'Manager Social Services'},
    {'label': 'Marketing Manager', 'value': 'Marketing Manager'},
    {'label': 'Medical Doctor', 'value': 'Medical Doctor'},
    {'label': 'Medical Laboratory Technician',
    'value': 'Medical Laboratory Technician'},
    {'label': 'Mortgage Broker', 'value': 'Mortgage Broker'},
    {'label': 'Musicians and Singers', 'value': 'Musicians and Singers'},
    {'label': 'Nurse', 'value': 'Nurse'},
    {'label': 'Other Animal Care Workers', 'value': 'Other Animal Care Workers'},
    {'label': 'Other Assisting In Support Of Health Services',
    'value': 'Other Assisting In Support Of Health Services'},
    {'label': 'Other Business Services Manager',
    'value': 'Other Business Services Manager'},
    {'label': 'Other Creative Designers', 'value': 'Other Creative Designers'},
    {'label': 'Other Instructors', 'value': 'Other Instructors'},
    {'label': 'Other Performers', 'value': 'Other Performers'},
    {'label': 'Other Products Assemblers, Finishers and Inspectors',
    'value': 'Other Products Assemblers, Finishers and Inspectors'},
    {'label': 'Other Professional Diagnosing and Treating',
    'value': 'Other Professional Diagnosing and Treating'},
    {'label': 'Other Professional Engineers',
    'value': 'Other Professional Engineers'},
    {'label': 'Other Professional In Social Science',
    'value': 'Other Professional In Social Science'},
    {'label': 'Other Repairers and Servicers',
    'value': 'Other Repairers and Servicers'},
    {'label': 'Other Sales Related', 'value': 'Other Sales Related'},
    {'label': 'Other Services Supervisor', 'value': 'Other Services Supervisor'},
    {'label': 'Other Techs In Therapy and Assessment',
    'value': 'Other Techs In Therapy and Assessment'},
    {'label': 'Other Trades Helpers and Labourers',
    'value': 'Other Trades Helpers and Labourers'},
    {'label': 'Other Wood Products Assemblers and Inspectors',
    'value': 'Other Wood Products Assemblers and Inspectors'},
    {'label': 'Paralegal', 'value': 'Paralegal'},
    {'label': 'Pharmacist', 'value': 'Pharmacist'},
    {'label': 'Photographer', 'value': 'Photographer'},
    {'label': 'Plumber', 'value': 'Plumber'},
    {'label': 'Police Officer', 'value': 'Police Officer'},
    {'label': 'Procurement', 'value': 'Procurement'},
    {'label': 'Professional in Advertising or Marketing',
    'value': 'Professional in Advertising or Marketing'},
    {'label': 'Professional In Religion', 'value': 'Professional In Religion'},
    {'label': 'Professional Physical Sciences',
    'value': 'Professional Physical Sciences'},
    {'label': 'Project Manager', 'value': 'Project Manager'},
    {'label': 'Psychologist', 'value': 'Psychologist'},
    {'label': 'Public Transit Operators', 'value': 'Public Transit Operators'},
    {'label': 'Real Estate Agent', 'value': 'Real Estate Agent'},
    {'label': 'Real Estate Manager', 'value': 'Real Estate Manager'},
    {'label': 'Restaurant and Food Service Manager',
    'value': 'Restaurant and Food Service Manager'},
    {'label': 'Retail Clerk', 'value': 'Retail Clerk'},
    {'label': 'Retired', 'value': 'Retired'},
    {'label': 'School Teacher', 'value': 'School Teacher'},
    {'label': 'Software Engineer', 'value': 'Software Engineer'},
    {'label': 'Student', 'value': 'Student'},
    {'label': 'Supervisor Oil and Gas Drilling and Services',
    'value': 'Supervisor Oil and Gas Drilling and Services'},
    {'label': 'Supervisor Other Products Manufacturing and Assembly',
    'value': 'Supervisor Other Products Manufacturing and Assembly'},
    {'label': 'Supervisor Plant', 'value': 'Supervisor Plant'},
    {'label': 'Support Broadcasting', 'value': 'Support Broadcasting'},
    {'label': 'Travel Agent', 'value': 'Travel Agent'},
    {'label': 'Travel Services Supervisor',
    'value': 'Travel Services Supervisor'},
    {'label': 'Unemployed', 'value': 'Unemployed'},
    {'label': 'University Professors and Lecturers',
    'value': 'University Professors and Lecturers'},
    {'label': 'Veterinarian', 'value': 'Veterinarian'}
]

export default occupations;