import React, { useEffect } from 'react';
import { TradingInformationData } from '../../../components/Types';
import s from './forms.module.scss';
import cn from 'classnames';
import { Checkbox, TextField, DatePicker, AutoCompleteSelect, DropdownInput } from '../../../components/UILib';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
import { fiatCurrencies } from '../../../constants/fiatCurrencies';
import { fieldValidatingHandler } from '../../../utils/validations';
import { CurrencyDataType, ApplicationStepStatus } from '../../../components/Types';
import { individualTradingFormRequired, individualTradingFormRequiredWithOtherPurpose } from '../../../constants/requiredFields';
import { useTheme } from '../../../contexts/ThemeContext';
import { SelectChangeEvent } from '@mui/material';
interface TradingAccountProps {
    tradingInformation: TradingInformationData;
    setTradingInformation: React.Dispatch<React.SetStateAction<TradingInformationData>>;
    setFormCompletionStatus: React.Dispatch<React.SetStateAction<ApplicationStepStatus>>;
    invalidInputFields: string[];
}
const tradingPurposes = [
    'Speculative',
    'Risk Management', 
    'Hedging', 
    'Other',
]
const flowsOfFundsForFirstTrade = [
    'Crypto to Crypto',
    'Fiat to Crypto',
    'Crypto to Fiat'
]
function TradingAccount({tradingInformation, setTradingInformation, setFormCompletionStatus, invalidInputFields}: TradingAccountProps) {
    const { theme } = useTheme();
    const handleFirstFlowOfFundChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setTradingInformation((prevState) => ({...prevState, flow_of_first_trade: value}))
    }
    // useEffect(() => {
    //     console.log(tradingInformation);
        
    // },[tradingInformation])
    const handleTradingPurposeChange = (event: SelectChangeEvent) => {
        console.log(event);
        const {
            target: { value },
        } = event;
        let newValue = typeof value === 'string' ? value.split(',') : value
        setTradingInformation(prevState => ({ ...prevState, purpose: newValue }));
    }
    const handleInputValueChange = (value: string, name: string) => {
        console.log('handleInputValueChange', name + " " + value);
        setTradingInformation((prevState) => ({...prevState, [name]: value}))
    }
    const handleCurrencyValueChange = (selection: AutoCompleteSelectDataType, name: string) => {
        console.log('handleCurrencyValueChange', name + " " + selection.value);
        setTradingInformation((prevState) => ({...prevState, [name]: selection.value}))
    }
    const handleFirstTradeDateChange = (value: any) => {
        if(value){
            setTradingInformation((prevState) => ({...prevState, date_of_first_trade: value.format()}))
        }
        else {
            setTradingInformation((prevState) => ({...prevState, date_of_first_trade: ''}))
        }
        
    }
    // useEffect(() => {
    //     // update completion status
    //     let result = tradingInformation.purpose.includes('others')? 
    //         fieldValidatingHandler(individualTradingFormRequiredWithOtherPurpose, tradingInformation)
    //         :fieldValidatingHandler(individualTradingFormRequired, tradingInformation)
    //     if(result.length === 0) {
    //         setFormCompletionStatus((prevState) => ({...prevState, 'Trading Account': true}))
    //     }
    //     else {
    //         setFormCompletionStatus((prevState) => ({...prevState, 'Trading Account': false}))
    //     }
    // }, [tradingInformation])
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Intended Purpose of Trading (select all that apply)<sup>*</sup></p>
                        <DropdownInput
                            optionList={tradingPurposes}
                            value={tradingInformation.purpose}
                            onChange={handleTradingPurposeChange}
                            multiple={true}
                            errorText={invalidInputFields.includes("Intended Purpose of Trading")? 'Please select valid intended purpose of trading.' : undefined}
                        />
                        {/* <div className={s.checkboxGrid}>
                            <div className={theme === 'dark' ? cn(s.darkFont,s.checkbox)  : cn(s.lightFont, s.checkbox)}>
                                <Checkbox onChange={() => handleTradingPurposeChange('speculative')} checked={tradingInformation.purpose.includes('speculative')} />
                                Speculative
                            </div>
                            <div className={theme === 'dark' ? cn(s.darkFont,s.checkbox)  : cn(s.lightFont, s.checkbox)}>
                                <Checkbox onChange={() => handleTradingPurposeChange('riskManagement')} checked={tradingInformation.purpose.includes('riskManagement')} />
                                Risk Management
                            </div>
                            <div className={theme === 'dark' ? cn(s.darkFont,s.checkbox)  : cn(s.lightFont, s.checkbox)}>
                                <Checkbox onChange={() => handleTradingPurposeChange('others')} checked={tradingInformation.purpose.includes('others')} />
                                Other
                            </div>
                            <div className={theme === 'dark' ? cn(s.darkFont,s.checkbox)  : cn(s.lightFont, s.checkbox)}>
                                <Checkbox onChange={() => handleTradingPurposeChange('hedging')} checked={tradingInformation.purpose.includes('hedging')} />
                                Hedging
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={cn(s.row)}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Please provide a brief description of the intended purpose of trading. {tradingInformation.purpose.includes('Other') ?<sup>*</sup>:null}</p>
                        <TextField
                            name="other_purpose"
                            value={tradingInformation.other_purpose}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required={tradingInformation.purpose.includes('Other')}
                            externalError={tradingInformation.purpose.includes('Other') && invalidInputFields.includes("Intended purpose of trading description")}
                            error={tradingInformation.purpose.includes('Other') && invalidInputFields.includes("Intended purpose of trading description") ? 'Please describe the intended purpose of trading.' : undefined}
                        />
                    </div>
                </div>
                {/* <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Please provide a description on the intended purpose of trading.</p>
                        <TextField
                            name="purpose_description"
                            value={tradingInformation.purpose_description}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required={false}
                        />
                    </div>
                </div> */}

                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Expected date for first trade <sup>*</sup></p>
                        <DatePicker 
                            width={'100%'} onChange={handleFirstTradeDateChange} 
                            value={tradingInformation.date_of_first_trade}
                            error={invalidInputFields.includes("Expected date for first trade")}
                            errorText={invalidInputFields.includes("Expected date for first trade")? 'Please enter a valid date for your first trade.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Please confirm the flow of funds for the intended first trade. <sup>*</sup></p>
                        <DropdownInput
                            optionList={flowsOfFundsForFirstTrade}
                            value={tradingInformation.flow_of_first_trade}
                            onChange={handleFirstFlowOfFundChange}
                            multiple={false}
                            errorText={invalidInputFields.includes("The flow of funds for the intended first trade")? 'Please select the flow of funds.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Enter the expected currency and size of the first trade. <sup>*</sup></p>
                        <div className={s.currencySelectContainer}>
                            <div className={s.currency}>
                                <AutoCompleteSelect
                                    optionList={fiatCurrencies}
                                    value={{value: tradingInformation.currency_of_first_trade, label: tradingInformation.currency_of_first_trade}}
                                    onChange={(e, value) => handleCurrencyValueChange(value, 'currency_of_first_trade')}
                                    currency={true}
                                    errorText={invalidInputFields.includes("Expected currency of the first trade")? 'Expected currency of the first trade' : undefined}
                                />
                            </div>
                            {/* {tradingInformation.currency_of_first_trade !== '' ? <div className={s.currencySymbol}>
                            {
                                tradingInformation.currency_of_first_trade === 'CAD' || tradingInformation.currency_of_first_trade === 'USD' ? '$'
                                : tradingInformation.currency_of_first_trade === 'GBP' ? '£' : '€'
                            }</div> : null} */}
                            <TextField
                                name="size_of_first_trade"
                                value={tradingInformation.size_of_first_trade}
                                onChange={handleInputValueChange}
                                variant="xs"
                                type='number'
                                amount={true}
                                externalError={invalidInputFields.includes("Expected size of the first trade")}
                                error={invalidInputFields.includes("Expected size of the first trade") ? 'Please enter expected size of your first trade.' : undefined}
                                leftElementClassName={s.currencySymbol}
                                leftElement={tradingInformation.currency_of_first_trade !== '' ? <span>{tradingInformation.currency_of_first_trade === 'CAD' || tradingInformation.currency_of_first_trade === 'USD' ? '$'
                                : tradingInformation.currency_of_first_trade === 'GBP' ? '£' : '€'}</span> : null}
                            />
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Enter the expected currency and monthly volume to be traded. <sup>*</sup></p>
                        <div className={s.currencySelectContainer}>
                            <div className={s.currency}>
                                <AutoCompleteSelect
                                    optionList={fiatCurrencies}
                                    value={{value: tradingInformation.currency_to_be_traded, label: tradingInformation.currency_to_be_traded}}
                                    onChange={(e, value) => handleCurrencyValueChange(value, 'currency_to_be_traded')}
                                    currency={true}
                                    errorText={invalidInputFields.includes("Expected monthly volume currency")? 'Expected monthly volume currency' : undefined}
                                />
                            </div>
                            {/* {tradingInformation.currency_to_be_traded !== '' ? <div className={s.currencySymbol}>
                                <TextField
                                    name="purposeDescription"
                                    value={
                                        tradingInformation.currency_to_be_traded === 'CAD' || tradingInformation.currency_to_be_traded === 'USD' ? '$'
                                        : tradingInformation.currency_to_be_traded === 'GBP' ? '£' : '€'
                                    }
                                    variant="xs"
                                    disabled
                                    required={false}
                                /></div> : null} */}
                            <TextField
                                name="monthly_volume_to_be_traded"
                                value={tradingInformation.monthly_volume_to_be_traded}
                                onChange={handleInputValueChange}
                                variant="xs"
                                type='number'
                                amount={true}
                                externalError={invalidInputFields.includes("Expected monthly volume to be traded")}
                                error={invalidInputFields.includes("Expected monthly volume to be traded") ? 'Please enter expected monthly volume to be traded.' : undefined}
                                leftElementClassName={s.currencySymbol}
                                leftElement={tradingInformation.currency_to_be_traded !== '' ? <span>{tradingInformation.currency_to_be_traded === 'CAD' || tradingInformation.currency_to_be_traded === 'USD' ? '$'
                                : tradingInformation.currency_to_be_traded === 'GBP' ? '£' : '€'}</span> : null}
                            />
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Source of Funding <sup>*</sup></p>
                        <TextField
                            name="funding_source"
                            value={tradingInformation.funding_source}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Source of Funding")}
                            error={invalidInputFields.includes("Source of Funding") ? 'Please enter your source of funding.' : undefined}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TradingAccount;