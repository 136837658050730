import React, { useEffect } from 'react';
import s from './forms.module.scss';
import cn from 'classnames';
import { Checkbox } from '../../../components/UILib';
import { useTheme } from '../../../contexts/ThemeContext';
import { ApplicationStepStatus } from '../../../components/Types';
interface DeclarationProps {
    declaration: boolean;
    setDeclaration: React.Dispatch<React.SetStateAction<boolean>>;
    setFormCompletionStatus: React.Dispatch<React.SetStateAction<ApplicationStepStatus>>;
}
function Declaration({declaration, setDeclaration, setFormCompletionStatus}: DeclarationProps) {
    const { theme } = useTheme();
    const handleConfirmCheckBoxChange = () => {
        setDeclaration(!declaration);
    }
    // useEffect(() => {
    //     setFormCompletionStatus((prevState) => ({...prevState, 'Declaration': declaration}))
    // },[declaration])
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={cn(s.row, s.addLessMargin)}>
                    <div className={s.inputSectionHalf}>
                        <div className={theme === 'dark' ? cn(s.darkFont,s.declarationTxt) : cn(s.lightFont, s.declarationTxt)}>In submitting and returning the above information/documents, I, the client, fully understand and confirm for and on behalf of myself that:</div>
                    </div>
                </div>
                <div className={cn(s.row, s.addLessMargin)}>
                    <div className={s.inputSectionHalf}>
                        <div className={theme === 'dark' ? cn(s.darkFont,s.declarationTxt) : cn(s.lightFont, s.declarationTxt)}>We have full power and authority to open the SDM Inc. Individual Account and to enter into the associated terms and conditions for and on behalf of the applicant.</div>
                    </div>
                </div>
                <div className={cn(s.row, s.addLessMargin)}>
                    <div className={s.inputSectionHalf}>
                        <div className={theme === 'dark' ? cn(s.darkFont,s.declarationTxt) : cn(s.lightFont, s.declarationTxt)}>The information provided in this Individual Application is to my/our knowledge true and complete.</div>
                    </div>
                </div>
                <div className={cn(s.row, s.addLessMargin)}>
                    <div className={s.inputSectionHalf}>
                        <div className={theme === 'dark' ? cn(s.darkFont,s.declarationTxt) : cn(s.lightFont, s.declarationTxt)}>We will notify SDM Inc., or where applicable its affiliates, of any changes to the information provided within at least 7 business days.</div>
                    </div>
                </div>
                <div className={cn(s.checkboxGrid, s.addMargin)}>
                    <div className={s.checkbox}>
                        <Checkbox onChange={handleConfirmCheckBoxChange} checked={declaration} />
                        <div className={theme === 'dark' ? cn(s.darkFont,s.declarationTxt) : cn(s.lightFont, s.declarationTxt)}>I Agree</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Declaration;