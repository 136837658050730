import axios from 'axios';
import { 
    ApplicationInformationData, 
    ResidentialInformationData,
    TradingInformationData,
    FinancialInformationData,
    BankingInformationData,
    WalletAddressData,
    ReferralInformationData,
} from '../components/Types';
import { UserFileUploadResponseData } from '../components/Types/UserAuthRequestType';
const API_URL = process.env.REACT_APP_BACKEND_API_URL

const instance = axios.create({
    baseURL: API_URL 
})

export const saveApplicantInformation = async(userId: number, applicant_information: ApplicationInformationData) => {
    try {
        const res = await instance.post('/individualApplicantInformation', {
            userId: userId,
            applicant_information: applicant_information
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveResidentialAddress = async(userId: number, residential_address: ResidentialInformationData) => {
    try {
        const res = await instance.post('/individualResidentialAddress', {
            userId: userId,
            applicant_information: residential_address
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveTradingInformation = async(userId: number, trading_info: TradingInformationData) => {
    try {
        const res = await instance.post('/individualTradingInformation', {
            userId: userId,
            applicant_information: trading_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveFinancialInformation = async(userId: number, financial_info: FinancialInformationData) => {
    try {
        const res = await instance.post('/individualFinancialInformation', {
            userId: userId,
            applicant_information: financial_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveBankingInformation = async(userId: number, banking_info: BankingInformationData[]) => {
    try {
        const res = await instance.post('/individualBankingInformation', {
            userId: userId,
            applicant_information: banking_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const uploadIndividualFile = async(file: File, userId: string, userFolderLink: string) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);
        formData.append('userFolderLink', userFolderLink);
        const response = await instance.post('/indUpload', formData)
        if (response.status !== 201) {
            throw new Error('Failed to upload file');
        }

        const data = response.data
        return data;
    }
    catch (err) {
        return false
    }
}
export const saveWalletInformation = async(userId: number, wallet_info: WalletAddressData[]) => {
    try {
        const res = await instance.post('/individualWalletInformation', {
            userId: userId,
            applicant_information: wallet_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveDeclarationInformation = async(userId: number, declaration_signed: boolean) => {
    try {
        let requestData = {
            signed: declaration_signed
        }
        const res = await instance.post('/individualDeclaration', {
            userId: userId,
            applicant_information: requestData
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveReferralInformation = async(userId: number, referral_info: ReferralInformationData) => {
    try {
        const res = await instance.post('/individualReferralInformation', {
            userId: userId,
            applicant_information: referral_info
        })
        return res
    }
    catch (err) {
        return false
    }
}


export const submitIndividualApplication = async(userId: number, userData: any ) => {
    try {
        let reqData = { completed: true }
        const res = await instance.post('/individualSubmission', {
            userId: userId,
            applicant_information: reqData,
            user_data: userData
        })
        return res
    }
    catch (err) {
        return false
    }
}