import React from 'react';
import s from './KYC.module.scss'
import Stepper from './Stepper';
import KYC from './KYC';
import ThemeControllerSection from './ThemeControllerSection';
function Boarding() {
    return (
        <div className={s.outer}>
            <Stepper/>
            <KYC />
            <ThemeControllerSection />
        </div>
    );
}

export default Boarding;