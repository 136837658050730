import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useTheme } from '../../../contexts/ThemeContext';
import 'react-toastify/dist/ReactToastify.css';

export interface TopCornerToasterProps {
    message?: React.ReactNode;
    error: boolean;
    success: boolean;
    show: boolean;
    closeToast: () => void;
    id: string;
    header?:string;
}
const TopCornerToaster = ({ 
    header, 
    message, 
    show,
    success,
    closeToast
}: TopCornerToasterProps) => {
    const { theme } = useTheme();
  // Function to display the toast
    const showToast = () => {
        if(success){
            toast.success(`${header}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                onClose: closeToast,
                theme: theme,
                progressStyle: {color: '#1b5e20',},
                style: { fontFamily: 'Avenir, sans-serif', fontSize: '16px' } // Custom success color
            });
        }
        else {
            toast.error(`${header}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                onClose: closeToast,
                theme: theme,
                progressStyle: {color: '#d32f2f',},
                style: { fontFamily: 'Avenir, sans-serif', fontSize: '16px' } // Custom error color
            });
        }
        // toast(`${header}`, {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     // draggable: true,
        //     progress: undefined,
        //     type: success? 'success':'error',
        //     onClose: closeToast,
        //     theme: theme,
        //     style: { fontFamily: 'Avenir, sans-serif', fontSize: '16px' }
        // });
    };

    // Effect to show the toast based on the show prop
    React.useEffect(() => {
        if (show) {
            showToast();
        }
    }, [show, header, message]);

    return (
        <ToastContainer containerId={'topToast'}/>
    );
};

export default TopCornerToaster;
