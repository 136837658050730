import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import cn from 'classnames';
import s from './Dropdown.module.scss';
import { FormHelperText } from '@mui/material';
import { useTheme } from '../../../contexts/ThemeContext';
interface DropdownDataType {
    label: string;
    value: string | number;
}
export interface DropdownProps {
    // radioGroupLabel?: string;
    optionList: string[];
    onChange?: (event: SelectChangeEvent) => void;
    value: any;
    size?: 'small' | 'medium';
    width?: string;
    multiple?: boolean;
    errorText?: string | undefined;
    // containerStyle?: any;
    // radioContainerStyle?: any;
}

const Dropdown: React.FC<DropdownProps> = ({
    optionList,
    width = '100%',
    value,
    size = 'small',
    multiple = true,
    onChange,
    errorText,
}: DropdownProps) => {
    const [open, setOpen] = React.useState(false);
    const { theme } = useTheme();
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
    };
    
    return (
        <FormControl size={size} sx={{
                width: width,
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderWidth: '1px',
                        borderColor: '#4d4d4d',
                        borderRadius: 1.1,
                    },
                    '&:hover fieldset': {
                        borderWidth: '2px',
                        borderColor: '#898989', 
                        borderRadius: 1.1,
                    },
                    '&.Mui-focused fieldset': {
                        borderWidth: '2px',
                        borderColor: '#0676ED', 
                        borderRadius: 1.1,
                    },
                    '&.Mui-error fieldset': {
                        borderWidth: '2px',
                        borderColor: '#f33', 
                        borderRadius: 1.1,
                    },
                },
                '& .MuiSelect-icon': {
                    color: '#4d4d4d', // Change this to match your desired caret color
                },
            }}
        >
            <Select
                value={value}
                onChange={onChange}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                multiple={multiple}
                error={!!errorText}
                sx={{
                    color: theme === 'dark' ? 'white' : 'black',
                    fontFamily: "'Avenir', sans-serif",
                }}
                renderValue={(selected) => <p style={{textAlign: 'left'}}>{multiple && Array.isArray(selected) ? selected.join(', ') : selected}</p>}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            width: 250, // Adjust the width as needed
                            color: theme === 'dark' ? 'white' : 'black',
                            backgroundColor: theme === 'dark' ? '#202020' : '#f4f4f4', // Set the desired background color
                        },
                    },
                }}
            >
                {optionList.map((option, index) => (
                    <MenuItem key={index} value={option}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                    }}>
                        {multiple? value.indexOf(option) > -1 ? <CheckIcon /> : null : null}
                        <span style={{
                            maxWidth: '95%', // Adjust based on the actual width of your menu
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}>{option}</span>
                    </MenuItem>
                ))}
            </Select>
            {errorText ? <FormHelperText sx={{color: '#f33', fontFamily: "'Avenir', sans-serif" }}>{errorText}</FormHelperText> : null}
        </FormControl>)
}
export default Dropdown;
