const icons = {
  home: import('./home.svg'),
  members: import('./members.svg'),
  finance: import('./finance.svg'),
  permissions: import('./permissions.svg'),
  notifications: import('./notifications.svg'),
  labels: import('./labels.svg'),
  help: import('./help.svg'),
  telegram: import('./telegram.svg'),
  bell: import('./bell.svg'),
  'arrow-up': import('./arrow-up.svg'),
  error: import('./error.svg'),
  warning: import('./warning.svg'),
  info: import('./info.svg'),
  success: import('./success.svg'),
  close: import('./close.svg'),
  calendar: import('./calendar.svg'),
  'circle-close': import('./circle-close.svg'),
  transfer: import('./transfer.svg'),
  'more-vert': import('./more-vert.svg'),
  'arrow-upward': import('./arrow-upward.svg'),
  'arrow-downward': import('./arrow-downward.svg'),
  reverse: import('./reverse.svg'),
  'expand-more': import('./expand-more.svg'),
  withdraw: import('./withdraw.svg'),
  deposit: import('./deposit.svg'),
  action: import('./action.svg'),
  'check-mark': import('./check-mark.svg'),
  'check-circle': import('./claimed.svg'),
  'square-rss': import('./square-rss.svg'),
  'cloud-check': import('./cloud-check.svg'),
  'chevron-right': import('./chevron-right.svg'),
  search: import('./search.svg'),
  sun: import('./sun.svg'),
  moon: import('./moon.svg')
};

export default icons;
