import axios from 'axios';
import { UserAuthRequestDataType, UserOTPVerificationDataType, UserBoardingRequestDataType } from '../components/Types/UserAuthRequestType';
const API_URL = process.env.REACT_APP_BACKEND_API_URL

const instance = axios.create({
    baseURL: API_URL 
})
export const getOTP = async (authUser: UserAuthRequestDataType) => {
    try {
        const res = await instance.post('/login', {
            authUser
        })
        return res.data
    } catch (err) {
        return false
    }
}

export const verifyOTP = async (userOTP: UserOTPVerificationDataType) => {
    try {
        const res = await instance.post('/verifyOTP', {
            otp: userOTP.otp,
            userId: userOTP.userId
        })
        return res.data
    } catch (err) {
        return false
    }
}

export const updateUserBoardingInfo = async(userId: number, userRegData: UserBoardingRequestDataType) => {
    try {
        const res = await instance.post('/userRegistration', {
            userId: userId,
            registrationData: userRegData
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const getAuthUserInfo = async(userId: number) => {
    try {
        const res = await instance.post('/authUser', {
            userId: userId
        })
        return res.data
    }
    catch (err) {
        return false
    }
}
export const getEntityAuthUserInfo = async(userId: number) => {
    try {
        const res = await instance.post('/authEntityUser', {
            userId: userId
        })
        return res.data
    }
    catch (err) {
        return false
    }
}
export const getKYCAccessToken = async(userEmail: string) => {
    try {
        const res = await instance.post('/generateSumsubToken', {
            email: encodeURIComponent(userEmail)
        })
        return res.data
    }
    catch (err) {
        console.log('getKYCAccessToken', err);
        return false
    }
}