import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { UILibPage, LoginPage, BoardingPage, Individual, Entity, VerifyOtp, KYCPage } from './pages';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { useTheme } from './contexts/ThemeContext';
function App() {
  const { theme, toggleTheme } = useTheme();
  return (
    <div className={`App-${theme}`}>
        <Routes>
            <Route path="/" element={<LoginPage/>}/>
            <Route path="/verify-otp" element={<VerifyOtp />}/>
            <Route path="/boarding" element={<BoardingPage />}/>
            <Route path="/individual" element={<Individual/>}/>
            <Route path="/entity" element={<Entity />}/>
            <Route path="/UIlibrary" element={<UILibPage />}/>
            <Route path="/KYC" element={<KYCPage />}/>
        </Routes>
    </div>
  );
}

export default App;
