import { BankingInformationData, EntityDocumentsData } from "../components/Types";

export function fieldValidatingHandler(requiredFields: Record<string, any>, validatingForm: Record<string, any>) {
    const emptyFields: string[] = [];
    Object.keys(requiredFields).forEach(field => {
        // Check if the field exists in validatingForm and if its value is considered empty
        if (field in validatingForm && (validatingForm[field] == "" || validatingForm[field] === null || validatingForm[field] === undefined || validatingForm.length === 0)) {
            // If the condition is true, push the field name to the emptyFields array
            emptyFields.push(requiredFields[field]);
        }
    });
    return emptyFields;
}
export function multiFormsValidatingHandler(requiredFields: Record<string, any>, validatingForm: Record<string, any>[]) {
    let resultArr: Record<string, any>[] = []
    for(let i = 0; i < validatingForm.length; i++) {
        // return array of missing required fields
        let result = fieldValidatingHandler(requiredFields, validatingForm[i])
        resultArr.push(result)
    }
    return resultArr
}

export function multiFormsValidatingHandlerForBanks(requiredFields: Record<string, any>, optionalRequiredFields: Record<string, any>, validatingForm: BankingInformationData[]) {
    let resultArr: Record<string, any>[] = []
    for(let i = 0; i < validatingForm.length; i++) {
        // return array of missing required fields
        let fields = {...requiredFields, ...optionalRequiredFields}
        let result = validatingForm[i].has_intermediary_bank ? fieldValidatingHandler(fields, validatingForm[i]):fieldValidatingHandler(requiredFields, validatingForm[i])
        resultArr.push(result)
    }
    return resultArr
}
export function multiUploadsValidatingHandler(requiredFields: Record<string, boolean>, validatingForm: EntityDocumentsData[]){
    return validatingForm
        .filter(doc => requiredFields[doc.type] && doc.doc_link === "")
        .map(doc => doc.type);
}