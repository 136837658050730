import React, { useEffect, useRef, useState, useCallback } from 'react';
import s from './forms.module.scss';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { IndividualDocumentsData } from '../../../components/Types';
import { UserFileUploadResponseData } from '../../../components/Types/UserAuthRequestType';
import { useTheme } from '../../../contexts/ThemeContext';
import { uploadIndividualFile } from '../../../api/individual';
import { ApplicationStepStatus } from '../../../components/Types';
import cn from 'classnames';
interface imageObj {
    name: string;
    image: any;
    imagePreview: any;
}

interface UploadDocumentsProps {
    uploadDocuements: IndividualDocumentsData;
    setUploadDocuments: React.Dispatch<React.SetStateAction<IndividualDocumentsData>>;
    userPublicFolderLink: string;
    userId: string;
    setFormCompletionStatus: React.Dispatch<React.SetStateAction<ApplicationStepStatus>>;
}
function UploadDocuments({uploadDocuements, setUploadDocuments, userPublicFolderLink, userId, setFormCompletionStatus}: UploadDocumentsProps) {
    const { theme } = useTheme();
    const [loading, setLoading] = useState(false);
    const hiddenUpdateFileInput = useRef<(HTMLDivElement | null)>()
    const [uploadImage, setUploadImage] = useState<imageObj>({
        name: '',
        image: null,
        imagePreview: ''
    })
    const onDrop = useCallback(async (acceptedFiles: any[]) => {
        setLoading(true)

        const fileUploaded = acceptedFiles[0];
        let newImg = {
            name: fileUploaded.name,
            image: URL.createObjectURL(fileUploaded),
            imagePreview: URL.createObjectURL(fileUploaded)
        };
        console.log(newImg);
        
        if (fileUploaded && userId !== '' && userPublicFolderLink) {
            let res = await uploadIndividualFile(fileUploaded, userId, userPublicFolderLink);
            if(res) {
                const uniqueString = new Date().getTime();
                let previewLink = extractFolderId(res)
                previewLink = `https://drive.google.com/thumbnail?id=${previewLink}&rand=${Date.now()}`
                //newImg.imagePreview = previewLink;
                setUploadDocuments((prevState) => ({...prevState, doc_link: res as string }));
            } else {
                // Handle error
                console.error('Failed to upload file');
            }
        } else {
            console.error('Missing user ID or folder link');
        }
        setUploadImage(newImg);
        setLoading(false);
    }, [userId, userPublicFolderLink, setUploadDocuments]);

    useEffect(() => {
        if(uploadDocuements){
            console.log('uploadDocuements.doc_link', uploadDocuements);
            
            let newImg = {} as imageObj
            newImg.name = uploadDocuements.type
            newImg.image = uploadImage.image
            let imageId = extractFolderId(uploadDocuements.doc_link)
            console.log('imageId', imageId);
            const uniqueString = new Date().getTime();
            newImg.imagePreview = imageId ? `https://drive.google.com/thumbnail?id=${imageId}&rand=${uniqueString}` : null
            setUploadImage((prevState) => ({...prevState, imagePreview: imageId ? `https://drive.google.com/thumbnail?id=${imageId}&rand=${uniqueString}` : null}))
            //setUploadImage(newImg);
        }
    },[uploadDocuements])
    // useEffect(() => {
    //     console.log('uploadImage', !!uploadImage);
        
    //     // update completion status
    //     setFormCompletionStatus((prevState) => ({...prevState, 'Proof of Funds': !!uploadImage}))
    // },[uploadImage])
    const handleClickUploadImage = () => {
        hiddenUpdateFileInput.current?.click();
    }
    const extractFolderId = (url: string) => {
        const match = url.match(/https:\/\/drive\.google\.com\/file\/d\/([^\/]+)/);
        return match ? match[1] : null;
    };
    const handleChangeUpdateImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        if (!event.target.files) {
            return;
        }
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded)
        let newImg = {} as imageObj
        newImg.name = fileUploaded.name
        newImg.image = fileUploaded
        newImg.imagePreview = URL.createObjectURL(fileUploaded)
        console.log(newImg);
        
        // setUploadImage(newImg)
        if (fileUploaded && userId !== '' && userPublicFolderLink) {
            let res = await uploadIndividualFile(fileUploaded, userId, userPublicFolderLink);
            console.log(res);
            if(res) {
                let previewLink = extractFolderId(res)
                
                previewLink = `https://drive.usercontent.google.com/download?id=${previewLink}&export=view`
                console.log(previewLink, previewLink);
                newImg.imagePreview = previewLink
                setUploadImage(newImg)
                setUploadDocuments((prevState) => ({...prevState, doc_link: previewLink as string}))
            }
            else {
                // TODO: DISPLAY ERROR MESSAGE FOR FAILED UPLOADED FILE
            }
            
        } else {
            console.log('Missing data');
        }
        setLoading(false)
    }
    useEffect(() => {
        console.log('uploadImage', uploadImage);
        
    }, [uploadImage])
    const isImageFile = (fileName: string): boolean => {
        // Define a set of image file extensions
        const imageExtensions = new Set(['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp', '.bmp', '.tiff', '.tif']);

        // Extract the file extension from the filename
        const extension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();

        // Check if the extracted extension is in the set of image extensions
        return imageExtensions.has(extension);
    }
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                <div className={s.inputSectionFull} {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ?
                            <div className={theme === 'dark' ? cn(s.imageUploader, s.dark) : cn(s.imageUploader, s.light)}>
                                <div className={theme === 'dark' ? cn(s.subDescription, s.darkFont) :cn(s.subDescription, s.lightFont)}>
                                    Drop the file here ...
                                </div>
                            </div> :
                            <div className={theme === 'dark' ? cn(s.imageUploader, s.dark) : cn(s.imageUploader, s.light)}>
                                {loading ? <CircularProgress className={s.loading} /> : null}
                                {!uploadImage.image && !uploadImage.imagePreview ? 
                                (
                                    <>
                                        <div className={s.innerContainer}>
                                            <UploadFileIcon className={theme === 'dark' ? cn(s.icon, s.darkFont) : cn(s.icon, s.lightFont)} />
                                            <div className={theme === 'dark' ? cn(s.description, s.darkFont) : cn(s.description, s.lightFont)}>
                                                Upload an Attachment
                                            </div>
                                        </div>
                                        <div className={theme === 'dark'? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>Bank statement showing proof of funds. <sup>*</sup></div>
                                    </>
                                )
                                : uploadImage.image ? isImageFile(uploadImage.name) ? (
                                    <img src={ uploadImage.image} alt={uploadImage.name ? 'Uploaded: ' + uploadImage.name : 'Uploaded: Proof of Funds'} className={theme === 'dark' ? cn(s.image, s.darkFont) : cn(s.image, s.lightFont)} />
                                ) : (<>
                                    <CloudDoneOutlinedIcon fontSize='large' className={theme === 'dark' ? cn(s.icon) : cn(s.icon)} color='success'/>
                                    <div className={s.innerContainer}>
                                        <div className={theme === 'dark' ? cn(s.description, s.darkFont) : cn(s.description, s.lightFont)}>
                                            Proof of Funds uploaded
                                        </div>
                                        
                                    </div>
                                    <div className={theme === 'dark'? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>Drag and drop new file to replace the existing file</div>
                                </>): (<>
                                    <CloudDoneOutlinedIcon fontSize='large' className={theme === 'dark' ? cn(s.icon) : cn(s.icon)} color='success'/>
                                    <div className={s.innerContainer}>
                                        <div className={theme === 'dark' ? cn(s.description, s.darkFont) : cn(s.description, s.lightFont)}>
                                            Proof of Funds uploaded
                                        </div>
                                        
                                    </div>
                                    <div className={theme === 'dark'? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>Drag and drop new file to replace the existing file</div>
                                </>)}
                            </div>
                        }
                    </div>
                    {/* <div className={s.inputSectionFull} >
                        {uploadImage?.imagePreview? 
                        <div className={theme === 'dark'? cn(s.imageUploader, s.dark) : cn(s.imageUploader, s.light)} onClick={() => handleClickUploadImage()}>
                            {loading? <CircularProgress className={s.loading}/>:null }
                            <input
                                type="file"
                                ref={el => hiddenUpdateFileInput.current = el}
                                onChange={(e) => handleChangeUpdateImage(e)}
                                style={{display: 'none'}}
                            />
                            <img
                                className={theme === 'dark'? cn(s.image, s.darkFont) : cn(s.image, s.lightFont)}
                                src={uploadImage.imagePreview} alt={'bank_statement uploaded: ' + uploadImage.name}
                            />
                            
                        </div>
                        :<div className={theme === 'dark'? cn(s.imageUploader, s.dark) : cn(s.imageUploader, s.light)} onClick={() => handleClickUploadImage()}>
                            {loading? <CircularProgress className={s.loading}/>:null }
                            <div className={s.innerContainer}>
                                <UploadFileIcon className={theme === 'dark'? cn(s.icon, s.darkFont) : cn(s.icon, s.lightFont)}/>
                                <input
                                    type="file"
                                    ref={el => hiddenUpdateFileInput.current = el}
                                    onChange={(e) => handleChangeUpdateImage(e)}
                                    style={{display: 'none'}}
                                />
                                <div className={theme === 'dark'? cn(s.description, s.darkFont) : cn(s.description, s.lightFont)} onClick={() => handleClickUploadImage()}>Upload an Attachment</div>
                            </div>
                            <div className={theme === 'dark'? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>Bank statement showing proof of funds. <sup>*</sup></div>
                        </div>}
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default UploadDocuments;