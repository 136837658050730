import React, { useEffect } from 'react';
import BankingForm from './BankingForm';
import s from './forms.module.scss';
import { BankingInformationData } from '../../../components/Types';
interface BankingInformationProps {
    bankingInformation: BankingInformationData[];
    setBankingInformation: React.Dispatch<React.SetStateAction<BankingInformationData[]>>;
    invalidInputFields: any[];
}
function BankingInformation({bankingInformation, setBankingInformation, invalidInputFields}: BankingInformationProps) {
    useEffect(() => {
        console.log(bankingInformation)
    },[bankingInformation])
    return (
        <div className={s.rightContainer}>
            {bankingInformation.map((bank, index) => <BankingForm index={index} bankInformation={bankingInformation} setBankingInformation={setBankingInformation} invalidInputFields={invalidInputFields[index]}/>)}
        </div>
    );
}

export default BankingInformation;