import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { RadioButtonDataType } from './types';
import cn from 'classnames';

import s from './RadioButtons.module.scss';

export interface RadioButtonsProps {
    radioGroupLabel?: string;
    optionList: RadioButtonDataType[];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | number | boolean;
    row?: boolean;
    width?: string;
    containerStyle?: any;
    radioContainerStyle?: any;
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
    radioGroupLabel,
    optionList,
    onChange,
    value,
    row = true,
    width = '70%',
    containerStyle,
    radioContainerStyle,
}: RadioButtonsProps) => {
    if(row){
        return (<FormControl sx={{width: width}}>
            {radioGroupLabel? <FormLabel sx={{color: '#a8a8a8', '&.Mui-focused': {
                                                    color: 'white', 
                                                    },}}>{radioGroupLabel}</FormLabel> : null}
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={onChange}
                row 
                sx={containerStyle? containerStyle:{
                    color: 'white',
                    justifyContent: 'space-evenly'
                }}
            >
                {optionList.map((option, index) => <FormControlLabel 
                                                    sx={radioContainerStyle? radioContainerStyle : null}
                                                    key={index}
                                                    value={option.value} 
                                                    control={<Radio sx={{
                                                        color: '#EBAF00', 
                                                        '&.Mui-checked': {
                                                        color: '#EBAF00', 
                                                        },
                                                    }} />} 
                                                    label={option.label} 
                                                />)}
            </RadioGroup>
        </FormControl>)
    }
    else return (
        <FormControl>
            {radioGroupLabel? <FormLabel className={s.label}>{radioGroupLabel}</FormLabel> : null}
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={onChange}
                sx={{
                    color: 'white', 
                }}  
            >
                {optionList.map((option, index) => <FormControlLabel sx={{borderWidth: '1px', borderStyle: 'solid', borderColor: 'white', borderRadius: '8px'}} key={index} value={option.value} control={<Radio sx={{
                                                    color: '#EBAF00', 
                                                    '&.Mui-checked': {
                                                    color: '#EBAF00', 
                                                    },
                                                }} />} label={option.label} />)}
            </RadioGroup>
        </FormControl>
    )
};

export default RadioButtons;
