import React, { useState, useRef, useEffect } from 'react';
import { AdditionalInformationData } from '../../../components/Types';
import { TextField, AutoCompleteSelect, RadioButtons, Button } from '../../../components/UILib';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
import s from './forms.module.scss';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import cn from 'classnames';
import countries from '../../../constants/countries';
import Divider from '@mui/material/Divider';
import { useTheme } from '../../../contexts/ThemeContext';
import { uploadEntityFile } from '../../../api/entity';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';
// const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''
import { loadGoogleScript } from '../../../utils/googleAddressAutoComplete';
interface AdditionalInformationProps {
    additionalInformation: AdditionalInformationData;
    setAdditionalInformation: React.Dispatch<React.SetStateAction<AdditionalInformationData>>;
    userPublicFolderLink: string;
    userId: string;
    invalidInputFields: string[];
}
interface fileObj {
    name: string;
    image: any;
}
const options = [{value: true, label: 'Yes'}, {value: false, label: 'No'}]
const sourcesOfFunding = [
    {value: 'Business Activities (e.g. sales)', label: 'Business Activities (e.g. sales)'},
    {value: 'Sale of Equity', label: 'Sale of Equity'},
    {value: 'Client Funds', label: 'Client Funds'},
    {value: 'Funds Generated from Business operations', label: 'Funds Generated from Business operations'},
    {value: 'Funds Generated from asset sale or Investment', label: 'Funds Generated from asset sale or Investment'},
    {value: 'Transfer from Owner, Parent company or associated entity', label: 'Transfer from Owner, Parent company or associated entity'},
    {value: 'Treasury Management', label: 'Treasury Management'},
    {value: 'Other', label: 'Other'},
]

const naturesOfBusiness = [
    {value: 'Consultancy/Advisory Service', label: 'Consultancy/Advisory Service'},
    {value: 'Miner/Hosting', label: 'Miner/Hosting'},
    {value: 'Crypto/Blockchain Project', label: 'Crypto/Blockchain Project'},
    {value: 'Crypto ATM Operator', label: 'Crypto ATM Operator'},
    {value: 'Investment Fund', label: 'Investment Fund'},
    {value: 'Marketing/Media', label: 'Marketing/Media'},
    {value: 'Payment Service', label: 'Payment Service'},
    {value: 'Real Estate Developer', label: 'Real Estate Developer'},
    {value: 'Service Provider', label: 'Service Provider'},
    {value: 'Trading/Exchange Desk', label: 'Trading/Exchange Desk'},
    {value: 'Bank', label: 'Bank'},
    {value: 'Other', label: 'Other'},
]

let countryList = countries.map(country => {return {value: country.name, label: country.name}})
function AdditionalInformation({additionalInformation, setAdditionalInformation, userPublicFolderLink, userId, invalidInputFields}: AdditionalInformationProps) {
    const { theme } = useTheme();
    const [loading, setLoading] = useState(false);
    const hiddenUpdateFileInput = useRef<(HTMLDivElement | null)>()
    const [uploadFile, setUploadFile] = useState<fileObj>()
    const autocompleteRegisteredInput = useRef<HTMLInputElement>(null);
    const autocompleteOperatingInput = useRef<HTMLInputElement>(null);
    console.log(additionalInformation.has_website);
    
    useEffect(() => {
        // Load script and initialize or use existing Google Maps
        let script: any;
        if (window.google) {
            initAutocompleteRegistered();
            if (additionalInformation.different_operating_address) {
                initAutocompleteOperating(); // Only initialize this if the state allows
            }
        } else {
            script = loadGoogleScript();
        }
    
        // Cleanup function to remove script and possibly other cleanups
        return () => {
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [additionalInformation]);
    // GOOGLE ADDRESS
    // const loadGoogleScript = () => {
    //     const script = document.createElement("script");
    //     script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places&callback=initGoogleAutocomplete`;
    //     script.async = true;
    //     document.body.appendChild(script);
    //     return script; // Return the script element for cleanup
    // };
    const initAutocompleteRegistered = () => {
        try {
            if (!autocompleteRegisteredInput.current) return;
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRegisteredInput.current, {
                types: ["address"], // You can specify the type of place data to return.
            });
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                console.log('place', place);
                let streetNumber: string = ''
                let streetAddress: string = ''
                let city: string = ''
                let country: string = ''
                let state: string = ''
                let postal: string = ''
                for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                    // @ts-ignore remove once typings fixed
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number": {
                            streetNumber = `${component.long_name} `;
                            break;
                        }
                        case "route": {
                            streetAddress = streetNumber + component.short_name;
                            break;
                        }
                        case "postal_code": {
                            postal = component.long_name;
                            break;
                        }
                        case "locality":
                            city = component.long_name
                            break;
                
                        case "administrative_area_level_1": {
                            state = component.long_name
                            break;
                        }
                
                        case "country":
                            country = component.long_name
                            break;
                    }
                }
                setAdditionalInformation((prevState) => ({...prevState, registered_address: streetAddress, registered_postal_code: postal, registered_city: city, registered_state:state, registered_country:country}))
            });
        }
        catch (err) {
            console.log('initAutocompleteRegistered', err);
        }
    };
    const initAutocompleteOperating = () => {
        // console.log('initAutocompleteOperating');
        try {
            if (!autocompleteOperatingInput.current) return;
            const autocompleteOperating = new window.google.maps.places.Autocomplete(autocompleteOperatingInput.current, {
                types: ["address"], // You can specify the type of place data to return.
            });
            autocompleteOperating.addListener("place_changed", () => {
                const placeOperating = autocompleteOperating.getPlace();
                console.log('operating place', placeOperating);
                let streetNumberOperating: string = ''
                let streetAddressOperating: string = ''
                let cityOperating: string = ''
                let countryOperating: string = ''
                let stateOperating: string = ''
                let postalOperating: string = ''
                for (const component of placeOperating.address_components as google.maps.GeocoderAddressComponent[]) {
                    // @ts-ignore remove once typings fixed
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number": {
                            streetNumberOperating = `${component.long_name} `;
                            break;
                        }
                        case "route": {
                            streetAddressOperating = streetNumberOperating + component.short_name;
                            break;
                        }
                        case "postal_code": {
                            postalOperating = component.long_name;
                            break;
                        }
                        case "locality":
                            cityOperating = component.long_name
                            break;
                
                        case "administrative_area_level_1": {
                            stateOperating = component.long_name
                            break;
                        }
                
                        case "country":
                            countryOperating = component.long_name
                            break;
                    }
                }
                setAdditionalInformation((prevState) => ({...prevState, operating_address: streetAddressOperating, operating_postal_code: postalOperating, operating_city: cityOperating, operating_state:stateOperating, operating_country:countryOperating}))
            });
        }
        catch (err) {
            console.log('initAutocompleteOperating', err);
            
        }
        
    };
    const handleClickUploadFile = () => {
        hiddenUpdateFileInput.current?.click();
    }

    // const extractFolderId = (url: string) => {
    //     const match = url.match(/https:\/\/drive\.google\.com\/file\/d\/([^\/]+)/);
    //     return match ? match[1] : null;
    // };
    const handleChangeUpdateFile= async(event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        if (!event.target.files) {
            return;
        }
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded)
        let newImg = {} as fileObj
        newImg.name = fileUploaded.name
        newImg.image = fileUploaded
        console.log(newImg);
        console.log(userId);
        console.log(userPublicFolderLink);
        
        
        if(fileUploaded && userId !== '' && userPublicFolderLink){
            console.log('upload');
            
            //uploadEntityFile
            let res = await uploadEntityFile(fileUploaded, userId, userPublicFolderLink, 'supplier_agreement');
            if(res) {
                //let previewLink = extractFolderId(res)
                //previewLink = `https://drive.google.com/thumbnail?id=${previewLink}`
                setAdditionalInformation((prevState) => ({...prevState, supplier_agreement_upload_url: res as string }));
            } else {
                // Handle error
                console.error('Failed to upload file');
            }
        }
        setUploadFile(newImg)
        setLoading(false)
    }
    const handleInputValueChange = (value: string, name: string) => {
        console.log('handleInputValueChange', name + " " + value);
        setAdditionalInformation((prevState) => ({...prevState, [name]: value}))
    }
    const handleSelectChange = (e: React.SyntheticEvent, value: AutoCompleteSelectDataType, name: string) => {
        console.log(value)
        setAdditionalInformation((prevState) => ({...prevState, [name]: value.label}))
    }
    const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        console.log(name, event);
        const value = event.target.value === 'true';
        setAdditionalInformation((prevState) => ({...prevState, [name]: value}));
    }
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Company Name <sup>*</sup></p>
                        <TextField
                            name="company_name"
                            value={additionalInformation.company_name}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Company Name")}
                            error={invalidInputFields.includes("Company Name") ? 'Please enter your company name.' : undefined}
                        />
                    </div>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Company Registered Number <sup>*</sup></p>
                        <TextField
                            name="company_registered_number"
                            value={additionalInformation.company_registered_number}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Company Registered Number")}
                            error={invalidInputFields.includes("Company Registered Number") ? 'Please enter your company registered number.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Contact Number <sup>*</sup></p>
                        <TextField
                            name="contact_number"
                            value={additionalInformation.contact_number}
                            onChange={handleInputValueChange}
                            variant="xs"
                            type='number'
                            externalError={invalidInputFields.includes("Contact Number")}
                            error={invalidInputFields.includes("Contact Number") ? 'Please enter your contact number.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Source of Funding <sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={sourcesOfFunding}
                            value={{value: additionalInformation.funding_source, label: additionalInformation.funding_source}}
                            onChange={(e, value) => handleSelectChange(e, value, 'funding_source')}
                            errorText={invalidInputFields.includes("Source of Funding")? 'Please select the source of funding.' : undefined}
                        />
                    </div>
                    {additionalInformation.funding_source === 'Other' ?
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Other Source of Funding Description<sup>*</sup></p>
                        <TextField
                            name="other_funding_source"
                            value={additionalInformation.other_funding_source}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required
                            externalError={invalidInputFields.includes("Other Source of Funding Description")}
                            error={invalidInputFields.includes("Other Source of Funding Description") ? 'Please provide a valid value here.' : undefined}
                        />
                    </div>: null}
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Nature of Business <sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={naturesOfBusiness}
                            value={{value: additionalInformation.nature_of_business, label: additionalInformation.nature_of_business}}
                            onChange={(e, value) => handleSelectChange(e, value, 'nature_of_business')}
                            errorText={invalidInputFields.includes("Nature of Business")? 'Please select the nature of your business.' : undefined}
                        />
                    </div>
                    {additionalInformation.nature_of_business === 'Other' ?
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Other Nature of Business Description<sup>*</sup></p>
                        <TextField
                            name="other_nature_of_business"
                            value={additionalInformation.other_nature_of_business}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required
                            externalError={invalidInputFields.includes("Other Nature of Business Description")}
                            error={invalidInputFields.includes("Other Nature of Business Description") ? 'Please provide a valid value here.' : undefined}
                        />
                    </div> : null}
                </div>
                <div className={s.row}>
                    <div className={theme === 'dark' ? cn(s.subHeader, s.addMoreMargin, s.alignLeft, s.darkFont) : cn(s.subHeader,s.addMoreMargin, s.alignLeft, s.lightFont)}>COMPANY REGISTERED ADDRESS</div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Street Address <sup>*</sup></p>
                        <TextField
                            ref={autocompleteRegisteredInput}
                            name="registered_address"
                            value={additionalInformation.registered_address}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Registered Street Address")}
                            error={invalidInputFields.includes("Registered Street Address") ? 'Please enter valid street address.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Country <sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={countryList}
                            value={{value: additionalInformation.registered_country, label: additionalInformation.registered_country}}
                            onChange={(e, value) => handleSelectChange(e, value, 'registered_country')}
                            errorText={invalidInputFields.includes("Registered Country")? 'Please select country.' : undefined}
                        />
                    </div>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>City <sup>*</sup></p>
                        <TextField
                            name="registered_city"
                            value={additionalInformation.registered_city}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Registered City")}
                            error={invalidInputFields.includes("Registered City") ? 'Please enter city.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>State/Province <sup>*</sup></p>
                            <TextField
                                name="registered_state"
                                value={additionalInformation.registered_state}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Registered State/Province")}
                                error={invalidInputFields.includes("Registered State/Province") ? 'Please enter state/province.' : undefined}
                            />
                        </div>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Postal Code <sup>*</sup></p>
                            <TextField
                                name="registered_postal_code"
                                value={additionalInformation.registered_postal_code}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Registered Postal Code")}
                                error={invalidInputFields.includes("Registered Postal Code") ? 'Please enter postal code.' : undefined}
                            />
                        </div>
                    </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Is the company’s operating address different from the registered address provided above?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'different_operating_address')} 
                            value={additionalInformation.different_operating_address}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black',
                                    margin: 0,
                                }}
                        />
                    </div>
                </div>
                {additionalInformation.different_operating_address ? 
                <div className={cn(s.subContainer, s.addMargin)}>
                    <div className={s.row}>
                        <div className={theme === 'dark' ? cn(s.subHeader, s.darkFont) : cn(s.subHeader, s.lightFont)}>OPERATING BUSINESS ADDRESS</div>
                    </div>
                    <div className={cn(s.row, s.addLessMargin, s.addMarginBottom)}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Street Address <sup>*</sup></p>
                            <TextField
                                ref={autocompleteOperatingInput}
                                name="operating_address"
                                value={additionalInformation.operating_address}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Operating Street Address")}
                                error={invalidInputFields.includes("Operating Street Address") ? 'Please enter operating address.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Country <sup>*</sup></p>
                            <AutoCompleteSelect
                                optionList={countryList}
                                value={{value: additionalInformation.operating_country, label: additionalInformation.operating_country}}
                                onChange={(e, value) => handleSelectChange(e, value, 'operatingCountry')}
                                errorText={invalidInputFields.includes("Operating Country")? 'Please select operating country.' : undefined}
                            />
                        </div>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>City <sup>*</sup></p>
                            <TextField
                                name="operating_city"
                                value={additionalInformation.operating_city}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Operating City")}
                                error={invalidInputFields.includes("Operating City") ? 'Please enter operating city.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>State/Province <sup>*</sup></p>
                            <TextField
                                name="operating_state"
                                value={additionalInformation.operating_state}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Operating State/Province")}
                                error={invalidInputFields.includes("Operating State/Province") ? 'Please enter operating state/province.' : undefined}
                            />
                        </div>
                        <div className={s.inputSectionHalf}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Postal Code <sup>*</sup></p>
                            <TextField
                                name="operating_postal_code"
                                value={additionalInformation.operating_postal_code}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Operating Postal Code")}
                                error={invalidInputFields.includes("Operating Postal Code") ? 'Please enter operating postal code.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={cn(s.row, s.addMargin)}>
                        <Divider className={s.sectionDivider}/>
                    </div>  
                </div>
                :null}
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Does your company have a website?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'has_website')} 
                            value={additionalInformation.has_website}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black',
                                    margin: 0,
                                }}
                        />
                    </div>
                    {additionalInformation.has_website ? <div className={s.inputSectionHalf} style={{marginBottom: '1%'}}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Please provide your website here <sup>*</sup></p>
                        <TextField
                            name="website"
                            value={additionalInformation.website}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Website")}
                            error={invalidInputFields.includes("Website") ? 'Please enter your company website.' : undefined}
                        />
                    </div> : null}
                </div>
                {!additionalInformation.has_website ? 
                <div className={s.subContainer}>
                    <div className={s.row}> 
                        <div className={theme === 'dark' ? cn(s.subHeader, s.alignLeft, s.darkFont) : cn(s.subHeader, s.alignLeft, s.lightFont) }>Please answer the following business related questions below. If a question does not apply to your company, please write <em>Not Applicable</em> or <em>N/A</em>.</div>
                    </div>
                    <div className={invalidInputFields.includes("Brief description of your product/service") ? cn(s.row, s.addMarginBottom) : cn(s.row, s.addLessMargin)}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Please provide a brief description of your company's product/service <sup>*</sup></p>
                            <TextField
                                name="service_description"
                                value={additionalInformation.service_description}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Brief description of your product/service")}
                                error={invalidInputFields.includes("Brief description of your product/service") ? 'Please provide a brief description of your product/service.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={invalidInputFields.includes("How do you market your company's product/service?") ? cn(s.row, s.addMoreMarginBottom) : cn(s.row, s.addLessMargin)}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>How do you market your company's product/service? <sup>*</sup></p>
                            <TextField
                                name="service_marketing_description"
                                value={additionalInformation.service_marketing_description}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("How do you market your company's product/service?")}
                                error={invalidInputFields.includes("How do you market your company's product/service?") ? 'Please provide a brief description of how you market your company’s product/service.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={invalidInputFields.includes("Where is the inventory stored") ? cn(s.row, s.addMoreMarginBottom) : cn(s.row, s.addLessMargin)}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>If you are selling a physical product, where is the inventory stored? <sup>*</sup></p>
                            <TextField
                                name="inventory_storage_description"
                                value={additionalInformation.inventory_storage_description}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Where is the inventory stored")}
                                error={invalidInputFields.includes("Where is the inventory stored") ? 'If applicable, please provide a brief description of where your physical inventory is stored.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={invalidInputFields.includes("Customer base") ? cn(s.row, s.addMoreMarginBottom): cn(s.row, s.addLessMargin)}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Customer base? i.e. B2C, B2B, Pricing? <sup>*</sup></p>
                            <TextField
                                name="customer_base"
                                value={additionalInformation.customer_base}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Customer base")}
                                error={invalidInputFields.includes("Customer base") ? 'Please provide a brief description of your company’s customer base and pricing.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={invalidInputFields.includes("Terms/Conditions of Sale") ? cn(s.row, s.addMarginBottom):cn(s.row, s.addLessMargin)}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Terms/Conditions of Sale, i.e. Return/Refund Policy <sup>*</sup></p>
                            <TextField
                                name="terms_and_conditions_of_sale"
                                value={additionalInformation.terms_and_conditions_of_sale}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Terms/Conditions of Sale")}
                                error={invalidInputFields.includes("Terms/Conditions of Sale") ? 'Please outline your Terms/Conditions of Sale, i.e. Return/Refund Policy.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={cn(s.row, s.addLessMargin)}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Supplier Agreement, contracts (if applicable)</p>
                            <Button
                                leftElement={<UploadFileIcon />}
                                variant="monochrome"
                                className={s.button}
                                size="lg"
                                onClick={() => handleClickUploadFile()}
                                disabled={loading}
                            >
                                {loading? 'Uploading ...':uploadFile? uploadFile.name + ' uploaded' : additionalInformation.supplier_agreement_upload_url? 'Supplier Agreement, contracts Uploaded': 'Click here to upload your file'}
                            </Button>
                            <input
                                type="file"
                                ref={el => hiddenUpdateFileInput.current = el}
                                onChange={(e) => handleChangeUpdateFile(e)}
                                style={{display: 'none'}}
                            />
                        </div>
                    </div>
                    <div className={cn(s.row, s.addMarginBottom)}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Company customer service number or customer service email <sup>*</sup></p>
                            <TextField
                                name="customer_service_email"
                                value={additionalInformation.customer_service_email}
                                onChange={handleInputValueChange}
                                variant="xs"
                                externalError={invalidInputFields.includes("Company customer service number or customer service email")}
                                error={invalidInputFields.includes("Company customer service number or customer service email") ? 'Please provide the company’s customer service number or customer service email.' : undefined}
                            />
                        </div>
                    </div>
                    <div className={cn(s.row, s.addMargin)}>
                        <Divider className={s.sectionDivider}/>
                    </div>  
                </div>
                :null}
                <div className={s.row}>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>LinkedIn</p>
                        <TextField
                            name="linkedin"
                            value={additionalInformation.linkedin}
                            onChange={handleInputValueChange}
                            variant="xs"
                        />
                    </div>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Instagram</p>
                        <TextField
                            name="instagram"
                            value={additionalInformation.instagram}
                            onChange={handleInputValueChange}
                            variant="xs"
                        />
                    </div>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Twitter</p>
                        <TextField
                            name="twitter"
                            value={additionalInformation.twitter}
                            onChange={handleInputValueChange}
                            variant="xs"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdditionalInformation;