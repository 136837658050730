import React, { InputHTMLAttributes, useState, useEffect } from 'react';
import cn from 'classnames';
import { Icon } from '../Icon';
import { useTheme } from '../../../contexts/ThemeContext';
import s from './TextField.module.scss';

export interface TextFieldProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onError'> {
    className?: string;
    inputClassName?: string;
    focusClassName?: string;
    inputContainerClassName?: string;
    leftElement?: JSX.Element | null;
    leftElementClassName?: string;
    rightElement?: JSX.Element;
    rightElementClassName?: string;
    labelClassName?: string;
    placeholder?: string;
    multiline?: boolean;
    label?: string;
    name: string;
    value: string;
    disabled?: boolean;
    helperText?: string;
    error?: string | JSX.Element;
    success?: boolean;
    type?: 'text' | 'number' | 'email' | 'password';
    variant?: 'xs' | 'sm' | 'md' | 'lg';
    maxLength?: number;
    amount?: boolean;
    externalError?: boolean,
    onChange?: (value: string, name: string) => void;
    onError?: (isError: boolean) => void;
    id?: string;
}

const validChars = /[0-9,]/;
const formatAmount = (value: string): string => {
    const numberValue = parseFloat(value.replace(/,/g, ''));
    if (!isNaN(numberValue)) {
        return new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2, // Adjust based on your requirements
        }).format(numberValue);
    }
    return value;
};
const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(({
    className,
    focusClassName,
    inputContainerClassName,
    leftElement,
    leftElementClassName,
    rightElement,
    rightElementClassName,
    inputClassName,
    labelClassName,
    placeholder,
    multiline = false,
    label,
    name,
    value,
    disabled = false,
    error,
    success = false,
    externalError = false,
    type = 'text',
    variant = 'md',
    maxLength,
    helperText,
    required = true,
    amount = false,
    onChange = () => console.log('default onChange'),
    onError = () => console.log('errror'),
    id = `input-${name}`,
}, ref) => {
    const { theme, toggleTheme } = useTheme();
    const [focus, setFocus] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(!error && !externalError);
    const [formattedValue, setFormattedValue] = useState<string>();
    useEffect(() => {
        //setIsValid(!error && !externalError);
        if (externalError) {
            onError(true); // Trigger the error callback if there's an external error
        }
    }, [error, externalError, onError]);
    useEffect(() => {
        if (amount && value) {
            setFormattedValue(formatAmount(value));
        }
        else if (amount && !value){
            setFormattedValue(value)
        }
        // else {
        //     setFormattedValue(value)
        // }
    }, [value, amount]);
    const validateEmail = (email:string) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log('re.test(String(email).toLowerCase())', re.test(String(email).toLowerCase()));
        
        setIsValid(re.test(String(email).toLowerCase()))
        onError(re.test(String(email).toLowerCase()))
        return re.test(String(email).toLowerCase());
    }
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value;
        if(type == 'email'){
            validateEmail(newValue)
        }
        if (amount) {
            // Remove all non-numeric characters for processing except minus and decimal
            newValue = newValue.replace(/[^0-9.-]/g, '');
            console.log(formatAmount(newValue));
            
            // Update state to reformat on render
            onChange(formatAmount(newValue), name);
        }
        // Call onChange with the cleaned numeric string
        onChange(newValue, name);
    };
    const handleBlur = () => {
        if (required && !value) {
            setIsValid(false)
            onError(false)
        }
        else {
            setIsValid(true)
            onError(true)
        }
        setFocus(false); // existing onBlur logic
    };
    return (
        <div className={cn(s.root, className)}>
        <div
            className={cn(
            s.container,
            {
                [s.withError]: error || !isValid || externalError,
                [s.success]: success,
                [s.disabled]: disabled,
                [s.focus]: focus,
                [s.filled]: value,
                [s.dark]: theme === 'dark',
                [s.light]: theme === 'light',
            },
            focus ? focusClassName : '',
            )}
        >
            <div className={cn(s.inputContainer, inputContainerClassName)}>
            {leftElement && (
                <div className={cn(s.leftElement, leftElementClassName)}>
                {leftElement}
                </div>
            )}
            {multiline ? (
                <textarea
                    // ref={ref}
                    id={id}
                    className={theme === 'dark' ? cn(s.input, s.textarea, inputClassName, s.dark) : cn(s.input, s.textarea, inputClassName, s.light)}
                    value={value}
                    placeholder={placeholder}
                    onChange={(e): void => onChange(e.currentTarget.value, name)}
                    onFocus={(): void => setFocus(true)}
                    onBlur={(): void => setFocus(false)}
                    disabled={disabled}
                    maxLength={maxLength}
                />
            ) : (
                <input
                    ref={ref}
                    id={id}
                    type={amount? 'text':type}
                    name={name}
                    className={theme === 'dark' ? cn(s.input, s[variant], inputClassName, s.dark): cn(s.input, s[variant], inputClassName, s.light)}
                    value={amount? formattedValue: value}
                    placeholder={placeholder}
                    onKeyPress={(e) => {
                        if (type === 'number' && !validChars.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    onChange={handleInputChange}
                    // onChange={(e): void => {
                    //     handleChange(e, name);
                    //     if (type === 'email') {
                    //         validateEmail(e.currentTarget.value)
                    //     }
                    // }}
                    onFocus={(): void => setFocus(true)}
                    onBlur={(): void => handleBlur()}
                    disabled={disabled}
                    autoComplete="off"
                />
            )}
            <label
                className={cn(s.label, s[variant], s.floatingLabel, labelClassName)}
                htmlFor={id}
            >
                {label}
            </label>
            <fieldset className={s.border} aria-hidden="true">
                <legend className={s.legend}>
                {label && <span>{label}</span>}
                </legend>
            </fieldset>
            {rightElement && (
                <div className={cn(s.rightElement, rightElementClassName)}>
                {rightElement}
                </div>
            )}
            </div>
        </div>

        {!error && helperText && (
            <p
            className={cn(s.helperText, {
                [s.short]: multiline && maxLength,
            })}
            >
            {helperText}
            </p>
        )}
        {error && (
            <p className={s.error}>
            {/* <Icon className={s.errorIcon} name="circle-close" /> */}
            <span className={s.errorMessage}>{error}</span>
            </p>
        )}
        {maxLength && (
            <p className={s.length}>{`${value.length}/${maxLength}`}</p>
        )}
        </div>
    );
});

export default TextField;
