import React from 'react';
import { DirectorData } from '../../../components/Types';
import { AdditionalAuthorizedUserData } from '../../../components/Types';
import DirectorForm from './DirectorForm';
import AuthorizedUsersForm from './AuthorizedUsersForm';
import { useTheme } from '../../../contexts/ThemeContext';
import cn from 'classnames';
import s from './forms.module.scss'
interface DirectorsAndAdditionalAuthorizedUsersProps {
    directors: DirectorData[];
    setDirectors: React.Dispatch<React.SetStateAction<DirectorData[]>>;
    authorizedUsers: AdditionalAuthorizedUserData[],
    setAuthorizedUsers: React.Dispatch<React.SetStateAction<AdditionalAuthorizedUserData[]>>;
    invalidDirectors: any[];
    invalidAuthUsers: any[];
}
function DirectorsAndAdditionalAuthorizedUsers({directors, setDirectors, authorizedUsers, setAuthorizedUsers, invalidDirectors, invalidAuthUsers}: DirectorsAndAdditionalAuthorizedUsersProps) {
    const { theme } = useTheme();
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={theme === 'dark' ? cn(s.subHeader, s.darkFont) : cn(s.subHeader, s.lightFont)}>LIST OF DIRECTORS</div>
                </div>
                {directors.map((director, index) => <DirectorForm directors={directors} setDirectors={setDirectors} director={director} index={index} invalidInputFields={invalidDirectors[index]}/>)}
                <div className={cn(s.row, s.addMargin)}>
                    <div className={theme === 'dark' ? cn(s.subHeader, s.darkFont) : cn(s.subHeader, s.lightFont)}>LIST OF ALL AUTHORIZED USERS OR SIGNATORIES</div>
                </div>
                {authorizedUsers.map((user, index) => <AuthorizedUsersForm authorizedUsers={authorizedUsers} setAuthorizedUsers={setAuthorizedUsers} authorizedUser={user} index={index} invalidInputFields={invalidAuthUsers[index]}/>)}
            </div>
        </div>
    );
}

export default DirectorsAndAdditionalAuthorizedUsers;