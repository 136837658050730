import React from 'react';
import s from './ApplicationSteps.module.scss';
import cn from 'classnames';
import { useTheme } from '../../contexts/ThemeContext';
import { useDesktopMediaQuery, useMobileMediaQuery, useTabletMediaQuery } from '../../utils/responsiveBreakpoints';

function ApplicationSteps() {
    const { theme, toggleTheme } = useTheme();
    return (
        <div className={s.leftContainer}>
            {theme === 'dark'? 
                <img className={s.headerImage} src={require('../../assets/SDM-dark.png')} alt="SDM" height={70} width='auto'/>
                :<img className={s.headerImageLight} src={require('../../assets/SDM-light.png')} alt="SDM" height={70} width='auto'/>
            }
            <div className={s.content}>
                <div className={theme === 'dark'? cn(s.header,s.dark) : cn(s.header,s.light)}>Trade with us</div>
                <div className={theme === 'dark'? cn(s.secondHeader, s.secondDark) : cn(s.secondHeader, s.secondLight)}>Onboarding is simple</div>
                <div>
                    <span className={s.counter}> 
                        <span className={theme === 'dark'? cn(s.round, s.roundDark, s.line, s.innerCount) : cn(s.round, s.roundLight, s.line, s.innerCount)}>01</span>
                        <p className={theme === 'dark'? cn(s.step, s.dark) : cn(s.step, s.light)}>Complete your Account Application</p>
                    </span>
                    <span className={s.counter}>
                        <span className={theme === 'dark'? cn(s.round, s.roundDark, s.line, s.innerCount) : cn(s.round, s.roundLight, s.line, s.innerCount)}>02</span>
                        <p className={theme === 'dark'? cn(s.step, s.dark) : cn(s.step, s.light)}>Await Compliance Approval</p>
                    </span>
                    <span className={s.counter}>
                        <span className={theme === 'dark'? cn(s.round, s.roundDark, s.line, s.innerCount) : cn(s.round, s.roundLight, s.line, s.innerCount)}>03</span>
                        <p className={theme === 'dark'? cn(s.step, s.dark) : cn(s.step, s.light)}>Complete your SDM Agreement</p>
                    </span>
                    <span className={s.counter}>
                        <span className={theme === 'dark'? cn(s.round, s.roundDark, s.innerCount) : cn(s.round, s.roundLight, s.innerCount)}>04</span>
                        <p className={theme === 'dark'? cn(s.step, s.dark) : cn(s.step, s.light)}>Fund your Account & Begin Trading</p>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ApplicationSteps;