export const individualApplicantFormRequired = {
    title: 'Title/Occupation',
    first_name: 'First Name', 
    last_name: 'Last Name', 
    date_of_birth: 'Date Of Birth', 
    country: 'Country', 
}

export const individualResidentialFormRequired = {
    street_address: 'Street Address', 
    city: 'City', 
    country: 'Country', 
    state: 'State', 
    postal_code: 'Postal Code', 
    phone_number: 'Phone Number' 
}

export const individualTradingFormRequiredWithOtherPurpose = {
    purpose: 'Intended Purpose of Trading',
    other_purpose: 'Intended purpose of trading description',
    date_of_first_trade: 'Expected date for first trade',
    flow_of_first_trade: 'The flow of funds for the intended first trade',
    currency_of_first_trade: 'Expected currency of the first trade',
    size_of_first_trade: 'Expected size of the first trade',
    currency_to_be_traded: 'Expected monthly volume currency',
    monthly_volume_to_be_traded: 'Expected monthly volume to be traded',
    funding_source: 'Source of Funding',
}

export const individualTradingFormRequired = {
    purpose: 'Intended Purpose of Trading',
    date_of_first_trade: 'Expected date for first trade',
    flow_of_first_trade: 'The flow of funds for the intended first trade',
    currency_of_first_trade: 'Expected currency of the first trade',
    size_of_first_trade: 'Expected size of the first trade',
    currency_to_be_traded: 'Expected monthly volume currency',
    monthly_volume_to_be_traded: 'Expected monthly volume to be traded',
    funding_source: 'Source of Funding',
}

export const individualFinancialFormRequired = {
    income_currency: 'Estimated annual income currency',
    income: 'Estimated annual income',
    liquid_asset_currency: 'Currency of the liquid assets',
    liquid_asset_value: 'Value of the liquid assets',
}

export const bankingFormRequired = {
    account_currency: 'Account Currency',
    bank_account_name: 'Name on Bank Account',
    account_number: 'IBAN/Account Number',
    routing_code:'Routing Code',
    swift_code: 'Swift/ABA/Sort Code',
    beneficiary_address: 'Beneficiary Address',
    bank_name: 'Bank Name',
    bank_address: 'Bank Address',
    country: 'Country',
}
export const bankingWithIntBank = {
    intermediary_bank_name: 'Intermediary Bank Name',
    intermediary_routing_number: 'Intermediary Routing Number',
    intermediary_bank_address: 'Intermediary Bank Address'
}
export const walletAddressFormRequired = {
    type: 'Wallet Type',
    wallet_address: 'Wallet Address'
}
export const referralFormRequired = {
    source: 'How did you hear about us?',
}

export const companyVerificationFormRequired = {
    applicant_legal_type: 'Applicant Legal Entity Type',
    country_of_incorporation: 'Country of Incorporation',
    date_of_incorporation: 'Date of Incorporation',
}
export const entityAdditionalInformationFormRequired = {
    company_name: 'Company Name',
    company_registered_number: 'Company Registered Number',
    contact_number: 'Contact Number',
    funding_source: 'Source of Funding',
    nature_of_business: 'Nature of Business',
    registered_address: 'Registered Street Address',
    registered_country: 'Registered Country',
    registered_city: 'Registered City',
    registered_state: 'Registered State/Province',
    registered_postal_code: 'Registered Postal Code',
}
export const entityAdditionalInformationOtherSourceFunding = {
    other_funding_source: 'Other Source of Funding Description',
}
export const entityAdditionalInformationOtherNatureBusiess = {
    other_nature_of_business: 'Other Nature of Business Description',
}
export const entityAdditionalInformationOperatingAddress = {
    operating_address: 'Operating Street Address',
    operating_country: 'Operating Country',
    operating_city: 'Operating City',
    operating_state: 'Operating State/Province',
    operating_postal_code: 'Operating Postal Code',
}
export const entityAdditionalInformationHasWebsite = {
    website: 'Website'
}
export const entityAdditionalInformationNoWebsite = {
    service_description: "Brief description of your product/service",
    service_marketing_description: "How do you market your company's product/service?",
    inventory_storage_description: "Where is the inventory stored",
    customer_base: 'Customer base',
    terms_and_conditions_of_sale: 'Terms/Conditions of Sale',
    customer_service_email: 'Company customer service number or customer service email'
}
export const entityTradingInformationFormRequired = {
    purpose: 'Intended Purpose of Trading',
    date_of_first_trade: 'Expected date for first trade',
    flow_of_first_trade: 'The flow of funds for the intended first trade',
    currency_of_first_trade: 'Expected currency of the first trade',
    size_of_first_trade: 'Expected size of the first trade',
    currency_to_be_traded: 'Expected monthly volume currency',
    monthly_volume_to_be_traded: 'Expected monthly volume to be traded',
    
}
export const entityTradingInformationOtherPurposeRequired = {
    purpose: 'Intended Purpose of Trading',
    other_purpose: 'Intended purpose of trading description',
    date_of_first_trade: 'Expected date for first trade',
    flow_of_first_trade: 'The flow of funds for the intended first trade',
    currency_of_first_trade: 'Expected currency of the first trade',
    size_of_first_trade: 'Expected size of the first trade',
    currency_to_be_traded: 'Expected monthly volume currency',
    monthly_volume_to_be_traded: 'Expected monthly volume to be traded',
}
export const entityFinancialFormRequired = {
    income_currency: 'Estimated annual income currency',
    income: 'Estimated annual income',
    liquid_asset_currency: 'Currency of the liquid assets',
    liquid_asset_value: 'Value of the liquid assets',
}
export const entityUBOFormRequired = {
    first_name: 'First Name',
    last_name: 'Last Name',
    date_of_birth: 'Date of Birth',
    email: 'Email',
    country: 'Country/Region',
    ownership: 'Ownership Percentage',
}
export const entityDirectorFormRequired = {
    full_name: 'Full Legal Name',
    email: 'Email Address',
    contact_number: 'Contact Number',
    nationality: 'Nationality/Jurisdiction',
}

export const entityAuthorizedUserFormRequired = {
    full_name: 'Name',
    email: 'Email Address',
    contact_number: 'Contact Number',
}

