export const currencies = [
    {
        value: 'BTC',
        label: 'BTC',
    },
    {
        value: 'ETH/ERC-20',
        label: 'ETH/ERC-20',
    },
    {
        value: 'TRC-20',
        label: 'TRC-20',
    },
    {
        value: 'SOL',
        label: 'SOL',
    },
    {
        value: 'BNB',
        label: 'BNB',
    },
]