import React from 'react';
import s from './ThemeControllerSection.module.scss';
import { ThemeSwitchToggle } from '../../components/UILib';
function ThemeControllerSection() {
    return (
        <div className={s.themeContainer}>
            <div className={s.theme}>
                <ThemeSwitchToggle />
            </div>
        </div>
    );
}

export default ThemeControllerSection;