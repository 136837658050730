import React, { useEffect } from 'react';
import BankingForm from './BankingForm';
import s from './forms.module.scss';
import { multiFormsValidatingHandler } from '../../../utils/validations';
import { bankingFormRequired } from '../../../constants/requiredFields';
import { BankingInformationData, ApplicationStepStatus } from '../../../components/Types';
interface BankingInformationProps {
    bankingInformation: BankingInformationData[];
    setBankingInformation: React.Dispatch<React.SetStateAction<BankingInformationData[]>>;
    setFormCompletionStatus: React.Dispatch<React.SetStateAction<ApplicationStepStatus>>;
    invalidInputFields: any[];
}
function BankingInformation({bankingInformation, setBankingInformation, setFormCompletionStatus, invalidInputFields}: BankingInformationProps) {
    // useEffect(() => {
    //     console.log(bankingInformation)
    //     let results = multiFormsValidatingHandler(bankingFormRequired, bankingInformation)
    //     let result =  results.every(arr => arr.length === 0) ? true: false
    //     setFormCompletionStatus((prevState) => ({...prevState, 'Banking Information': result}))
    // },[bankingInformation])
    return (
        <div className={s.rightContainer}>
            {bankingInformation.map((bank, index) => <BankingForm index={index} bankInformation={bankingInformation} setBankingInformation={setBankingInformation} invalidInputFields={invalidInputFields[index]}/>)}
        </div>
    );
}

export default BankingInformation;