import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import s from './LoginSection.module.scss';
import { TextField, Checkbox, Button, ThemeSwitchToggle, Modal } from '../../components/UILib';
import { useDesktopMediaQuery, useMobileMediaQuery, useTabletMediaQuery } from '../../utils/responsiveBreakpoints';
import { useTheme } from '../../contexts/ThemeContext';
import { getOTP } from '../../api/applicantion';

function LoginSection() {
    const { theme, toggleTheme } = useTheme();
    const [email, setEmail] = useState('');
    const [tcCheck, setTcCheck] = useState(false);
    const [emailInputValid, setEmailInputValid] = useState(false);
    const [usaPatriotActCheck, setUsaPatriotActCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const handleTCCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTcCheck(event.target.checked);
    };
    const handleUsaPatriotCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsaPatriotActCheck(event.target.checked);
    };
    const handleProceedToOTP = async() => {
        setLoading(true)
        let authUser = {
            username: email
        }
        let res = await getOTP(authUser)
        console.log(res);
        if(res.id) {
            // succuess request
            setLoginFailed(false)
            setLoginSuccess(true)
        }
        else {
            setLoginFailed(true)
            setLoginSuccess(false)
        }
        setLoading(false)
    }
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <h1 className={theme === 'dark'? s.dark : s.light}>Login<br></br><span className={theme === 'dark'? '' : s.lightSpan}>To continue</span></h1>
                <p className={theme === 'dark'? s.dark : s.light}>Email <sup>*</sup></p>
                <div className={s.inputSection}>
                    <TextField
                        name="email"
                        value={email}
                        onChange={setEmail}
                        onError={setEmailInputValid}
                        variant="xs"
                        type='email'
                    />
                </div>
                
                
                <div className={cn(s.checkboxSection, s.firstCheckContainer)}>
                    <Checkbox onChange={handleTCCheckboxChange} checked={tcCheck} />
                    <div className={s.checkboxDescription}>
                        <p className={theme === 'dark'? s.dark : s.light} style={{margin: 0}}>I accept the <span className={theme === 'dark'? cn(s.dark, s.link) : cn(s.light, s.link)} onClick={() =>  window.open("https://sdm.co/terms-and-conditions/", "_blank")}>Terms and Conditions</span>,&nbsp;
                            <span className={theme === 'dark'? cn(s.dark, s.link) : cn(s.light, s.link)} onClick={() => window.open('https://sdm.co/privacy-policy/', "_blank")}>Privacy Policy</span>,&nbsp;
                            <span className={theme === 'dark'? cn(s.dark, s.link) : cn(s.light, s.link)} onClick={() => window.open('https://sdm.co/cookie-policy/', "_blank")}>Cookie Policy</span>&nbsp;
                        </p>
                    </div>
                    
                </div>
                <div className={cn(s.checkboxSection, s.firstCheckContainer)}>
                    <Checkbox onChange={handleUsaPatriotCheckboxChange} checked={usaPatriotActCheck} />
                    <div className={s.checkboxDescription}>
                        <p className={theme === 'dark'? s.dark : s.light} style={{margin: 0}}>I have read and I accept the &nbsp;
                            <span className={theme === 'dark'? cn(s.dark, s.link) : cn(s.light, s.link)} onClick={() => window.open('https://portal.securedigitalmarkets.com/usa-patriot-act.html', "_blank")}>USA Patriot Act Disclosure Statement</span>
                        </p>
                    </div>
                </div>
                <div className={s.buttonSection}>
                    <Button
                        className={s.button}
                        size="lg"
                        variant='brand'
                        onClick={handleProceedToOTP}
                        disabled={!emailInputValid || !tcCheck || !usaPatriotActCheck || loading}
                    >
                        {loading? 'Sending Login Email...' : 'Proceed to form'}
                    </Button>
                </div>
                {/* <div className={s.checkboxSection}>
                    <ThemeSwitchToggle />
                </div> */}
                    {/* <IconButton sx={theme === 'dark' ? { ml: 1, color: 'white'} : { ml: 1, color: 'black'}} onClick={toggleTheme} color="inherit">
                        {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                    <p className={theme === 'dark'? s.dark : s.light}>{theme === 'dark' ? 'Dark Mode' : 'Light Mode'}</p> */}
                
            </div>
            <Modal open={loginSuccess} setOpen={setLoginSuccess} isSuccess={true} isFailed={false} header={'Login Successful'} message={'An e-mail will be sent with a link to the email address you just provided.'}/>
            <Modal open={loginFailed} setOpen={setLoginFailed} isSuccess={false} isFailed={true} header={'Login Failed'} message={'Failed to sent the OTP link to the Email you provided, please try again later.'}/>
        </div>
    );
}

export default LoginSection;