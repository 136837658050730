import { useMediaQuery } from 'react-responsive';
import { ReactNode } from 'react';
interface ResponsiveLayoutProps {
    children: ReactNode
}
export const useDesktopMediaQuery = () =>
    useMediaQuery({ minWidth: 992 })

export const useTabletMediaQuery = () =>
    useMediaQuery({ minWidth: 768, maxWidth: 991 })

export const useMobileMediaQuery = () =>
    useMediaQuery({ maxWidth: 767 })

export const Desktop = ({ children } : ResponsiveLayoutProps ) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? <>{children}</> : null
}
export const Tablet = ({ children } : ResponsiveLayoutProps) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? <>{children}</> : null
}
export const Mobile = ({ children } : ResponsiveLayoutProps) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? <>{children}</> : null
}
export const Default = ({ children } : ResponsiveLayoutProps) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? <>{children}</> : null
}