import React, { useEffect, useState } from 'react';
import { CompanyVerificationData } from '../../../components/Types';
import s from './forms.module.scss';
import { AutoCompleteSelect, TextField, DatePicker, RadioButtons } from '../../../components/UILib';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
import { legalEntityTypes } from '../../../constants/legalEntityTypes';
import countries from '../../../constants/countries';
import { useTheme } from '../../../contexts/ThemeContext';
interface CompanyVerificationProps {
    companyVerification: CompanyVerificationData;
    setCompanyVerification: React.Dispatch<React.SetStateAction<CompanyVerificationData>>;
    invalidInputFields: string[];
}

const options = [{value: true, label: 'Yes'}, {value: false, label: 'No'}]
let countryList = countries.map(country => {return {value: country.name, label: country.name}})

function CompanyVerification({companyVerification, setCompanyVerification, invalidInputFields}: CompanyVerificationProps) {
    const { theme } = useTheme();
    const [legalEntityType, setLegalEntityType] = useState<AutoCompleteSelectDataType | null>(null);
    useEffect(() => {
        if(companyVerification.applicant_legal_type){
            let type = legalEntityTypes.filter(c => c.label === companyVerification.applicant_legal_type)[0]
            setLegalEntityType(type)
            // set states list based on country
        }
    }, [companyVerification])
    const handleLegalTypeChange = (e: React.SyntheticEvent, value: AutoCompleteSelectDataType) => {
        console.log(value)
        setCompanyVerification((prevState) => ({...prevState, applicant_legal_type: value.label || 'Corporation'}))
        setLegalEntityType({label: value.label, value: value.label})
    }
    const handleCountryChange = (e: React.SyntheticEvent, value: AutoCompleteSelectDataType) => {
        console.log(value)
        setCompanyVerification((prevState) => ({...prevState, country_of_incorporation: value.label || ''}))
    }
    const handleInputValueChange = (value: string, name: string) => {
        console.log('handleInputValueChange', name + " " + value);
        setCompanyVerification((prevState) => ({...prevState, [name]: value}))
    }
    const handleDOIChange = (value: any) => {
        console.log('handleDOIChange', value);
        if(value){
            setCompanyVerification((prevState) => ({...prevState, date_of_incorporation: value.format()}))
        }
        else {
            setCompanyVerification((prevState) => ({...prevState, date_of_incorporation: ''}))
        }
    }
    const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        console.log(name, event);
        const value = event.target.value === 'true';
        setCompanyVerification((prevState) => ({...prevState, [name]: value}));
    }
    useEffect(() => {
        console.log('companyVerification', companyVerification);
        
    },[companyVerification])
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Applicant Legal Entity Type <sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={legalEntityTypes}
                            value={{value: companyVerification.applicant_legal_type, label: companyVerification.applicant_legal_type}}
                            onChange={handleLegalTypeChange}
                            disableClearable
                        />
                    </div>
                    {/* {companyVerification.applicant_legal_type == 'Other' ?
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>(Please provide type in the textbox)</p>
                        <TextField
                            name="other_legal_type"
                            value={companyVerification.other_legal_type}
                            onChange={handleInputValueChange}
                            variant="xs"
                        />
                    </div> : null} */}
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Legal Entity Identifier/Natural Person Identifier/Privacy Law Identifier</p>
                        <TextField
                            name="legal_identifier"
                            value={companyVerification.legal_identifier}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required={false}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Country of Incorporation <sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={countryList}
                            value={{value: companyVerification.country_of_incorporation, label: companyVerification.country_of_incorporation}}
                            onChange={handleCountryChange}
                            errorText={invalidInputFields.includes("Country of Incorporation")? 'Please select a valid country of incorporation' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Date of Incorporation <sup>*</sup></p>
                        <DatePicker width={'100%'} onChange={handleDOIChange} value={companyVerification.date_of_incorporation} error={invalidInputFields.includes("Date of Incorporation")} errorText={invalidInputFields.includes("Date of Incorporation")? 'Please enter valid date of incorporation.' : undefined}/>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Is the company regulated by any government agency?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'regulated_by_gov')} 
                            value={companyVerification.regulated_by_gov}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black', 
                                    margin: 0,
                                }}
                        />
                        {companyVerification.regulated_by_gov ? 
                        <>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Agency Name</p>
                            <TextField
                            name="regulation_agency"
                            value={companyVerification.regulation_agency}
                            onChange={handleInputValueChange}
                            variant="xs"
                        />
                        </>
                        :null}
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Has the applicant or any of its directors ever declared bankruptcy? If so, please explain further.</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'bankruptcy')} 
                            value={companyVerification.bankruptcy}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black', 
                                    margin: 0,
                                }}
                        />
                        {companyVerification.bankruptcy? 
                        <>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Enter Details</p>
                            <TextField
                                name="bankruptcy_details"
                                value={companyVerification.bankruptcy_details}
                                onChange={handleInputValueChange}
                                variant="xs"
                            />
                        </>
                        :null}
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Is the applicant or any of its shareholders or directors a politically exposed person ("PEP")? If so, please explain further.</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'pep')} 
                            value={companyVerification.pep}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black', 
                                    margin: 0,
                                }}
                        />
                        {companyVerification.pep? 
                        <>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Enter Details</p>
                            <TextField
                                name="pep_details"
                                value={companyVerification.pep_details}
                                onChange={handleInputValueChange}
                                variant="xs"
                            />
                        </>
                        :null}
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Is the company registered in the United States as a financial entity?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'financial_entity_in_us')} 
                            value={companyVerification.financial_entity_in_us}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black', 
                                    margin: 0,
                                }}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Is the company a swap dealer?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'swap_dealer')} 
                            value={companyVerification.swap_dealer}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black',  
                                    margin: 0,
                                }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyVerification;