import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '../../../contexts/ThemeContext';
import { entityDocumentTypes, flowOfFundsFromFiatToCrypto, moneyServiceBusiness } from '../../../constants/entityDocumentTypes';
import s from './forms.module.scss';
import cn from 'classnames';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
interface DocumentDropZoneProps {
    type: string;
    onFileAccepted: (acceptedFiles: File[]) => void;
    existingFile?: string;
    legalEntityType: string;
    loading: boolean
}
const DocumentDropZone = ({ type, onFileAccepted, existingFile, legalEntityType, loading }: DocumentDropZoneProps) => {
    const { theme } = useTheme();
    const uniqueString = new Date().getTime();
    const extractFolderId = (url: string) => {
        const match = url.match(/https:\/\/drive\.google\.com\/file\/d\/([^\/]+)/);
        return match ? match[1] : null;
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onFileAccepted,
        maxFiles: 1,
        multiple: false,
    });
    const isStandardUrl = (url:string) => {
        const pattern = /^https?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#\[\]@!$&'()*+,;=]+$/;
        return pattern.test(url);
    }
    // const isImageFile = (fileName: string): boolean => {
    //     // Define a set of image file extensions
    //     const imageExtensions = new Set(['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp', '.bmp', '.tiff', '.tif']);

    //     // Extract the file extension from the filename
    //     const extension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();

    //     // Check if the extracted extension is in the set of image extensions
    //     return imageExtensions.has(extension);
    // }
    return (
        <div className={s.row}>
            <div className={cn(s.inputSectionFull)}>
                <p className={theme === 'dark' ? s.darkFont : s.lightFont}>
                    {type}{type == 'Additional file (optional)' ? null: !entityDocumentTypes[legalEntityType][type] && !Object.keys(moneyServiceBusiness).includes(type) && !Object.keys(flowOfFundsFromFiatToCrypto) ? null:<sup>*</sup>}</p>
                {type == 'Board Resolution Authorizing an Account Opening with SDM'?
                    <p className={theme === 'dark' ? cn(s.darkFont, s.smallFont, s.centeredContent) : cn(s.lightFont,s.smallFont, s.centeredContent)}>
                        <SimCardDownloadOutlinedIcon sx={{fontSize: 'small', marginRight: '2px'}}/><a className={s.customLink} href="/assets/templates/board.doc" download="board-resolution-template.doc">Download Template</a>
                    </p> 
                : null}
                <div {...getRootProps()} >
                <input {...getInputProps()} />
                {isDragActive ?
                <div className={theme === 'dark' ? cn(s.imageUploader, s.dark) : cn(s.imageUploader, s.light)}>
                    <div className={theme === 'dark' ? cn(s.subDescription, s.darkFont) :cn(s.subDescription, s.lightFont)}>
                        Drop the file here ...
                    </div>
                </div> :
                <div className={theme === 'dark' ? cn(s.imageUploader, s.dark) : cn(s.imageUploader, s.light)}>
                    {loading ? <CircularProgress className={s.loading} /> : null}
                    {existingFile ? isStandardUrl(existingFile) ? 
                    (<>
                        <CloudDoneOutlinedIcon fontSize='large' className={theme === 'dark' ? cn(s.icon) : cn(s.icon)} color='success'/>
                        <div className={s.innerContainer}>
                            <div className={theme === 'dark' ? cn(s.description, s.darkFont) : cn(s.description, s.lightFont)}>
                                {type} uploaded
                            </div>
                            
                        </div>
                        <div className={theme === 'dark'? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>Drag and drop new file to replace the existing file</div>
                    </>)
                    :(
                        <>
                        <CloudDoneOutlinedIcon fontSize='large' className={theme === 'dark' ? cn(s.icon) : cn(s.icon)} color='success'/>
                        <div className={s.innerContainer}>
                            <div className={theme === 'dark' ? cn(s.description, s.darkFont) : cn(s.description, s.lightFont)}>
                                {type} uploaded
                            </div>
                            
                        </div>
                        <div className={theme === 'dark'? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>Drag and drop new file to replace the existing file</div>
                    </>
                    ) : (
                        <>
                        <div className={s.innerContainer}>
                            <UploadFileIcon className={theme === 'dark' ? cn(s.icon, s.darkFont) : cn(s.icon, s.lightFont)} />
                            <div className={theme === 'dark' ? cn(s.description, s.darkFont) : cn(s.description, s.lightFont)}>
                                Upload an Attachment
                            </div>
                        </div>
                        <div className={theme === 'dark'? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>{type}</div>
                    </>
                    )}
                </div>}
                </div>
            </div>
        </div>
    );
};
export default DocumentDropZone;