import React from 'react';
import s from './forms.module.scss';
import { TextField, AutoCompleteSelect } from '../../../components/UILib';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
import { ReferralInformationData } from '../../../components/Types';
import { useTheme } from '../../../contexts/ThemeContext';
interface ReferralProps {
    referralInformation: ReferralInformationData;
    setReferralInformation: React.Dispatch<React.SetStateAction<ReferralInformationData>>;
    invalidInputFields: string[];
}
const referralTypes = [
    {value: 'Trade Show/Event', label: 'Trade Show/Event'}, 
    {value: 'Referral', label: 'Referral'},
    {value: 'Web Search', label: 'Web Search'},
    {value: 'Other', label: 'Other'}
]
function Referral({referralInformation, setReferralInformation, invalidInputFields}: ReferralProps) {
    const { theme } = useTheme();
    const handleReferralTypeChange = (selection: AutoCompleteSelectDataType) => {
        console.log('handleReferralTypeChange', selection.value);
        setReferralInformation((prevState) => ({...prevState, source: selection.value as string}))
    }
    const handleInputValueChange = (value: string, name: string) => {
        console.log('handleInputValueChange', name + " " + value);
        setReferralInformation((prevState) => ({...prevState, [name]: value}))
    }
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>How did you hear about us? <sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={referralTypes}
                            value={{value: referralInformation.source, label: referralInformation.source}}
                            onChange={(e, value) => handleReferralTypeChange(value)}
                            errorText={invalidInputFields && invalidInputFields.includes("How did you hear about us?")? 'Please select a valid value.' : undefined}
                        />
                    </div>
                </div>
                {referralInformation.source == '' || referralInformation.source == 'Web Search' ?
                    null
                    :<div className={s.row}>
                        <div className={s.inputSectionFull}>
                            <p className={theme === 'dark' ? s.darkFont : s.lightFont}>
                                {referralInformation.source == 'Trade Show/Event' ? 'Please list the Trade Show/Event where you heard about Secure Digital Markets.'
                                : referralInformation.source == 'Referral' ? 'Please list who you were referred by below.' 
                                : 'Please provide a brief description of how you heard about Secure Digital Markets.'}
                            </p>
                            <TextField
                                name="referred_by"
                                value={referralInformation.referred_by}
                                onChange={handleInputValueChange}
                                variant="xs"
                                required={false}
                            />
                        </div>
                </div>}
            </div>
        </div>
    );
}

export default Referral;