import React from 'react';
import s from './forms.module.scss';
import cn from 'classnames';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AdditionalAuthorizedUserData } from '../../../components/Types';
import { useTheme } from '../../../contexts/ThemeContext';
import { Checkbox, TextField, DatePicker, AutoCompleteSelect, Button } from '../../../components/UILib';
interface AuthorizedUsersFormProps {
    authorizedUsers: AdditionalAuthorizedUserData[]; 
    setAuthorizedUsers: React.Dispatch<React.SetStateAction<AdditionalAuthorizedUserData[]>>;
    authorizedUser: AdditionalAuthorizedUserData;
    index: number;
    invalidInputFields: string[];
}

function AuthorizedUsersForm({authorizedUsers, setAuthorizedUsers, authorizedUser, index, invalidInputFields}: AuthorizedUsersFormProps) {
    const { theme } = useTheme();
    const handleInputValueChange = (value: string, name: string) => {
        console.log('handleInputValueChange', name + " " + value);
        const updatedAccounts = authorizedUsers.map((account, idx) => {
            if (idx === index) {
                return {...account, [name]: value };
            }
            return account;
        });
        setAuthorizedUsers(updatedAccounts)
    }
    const addNextAuthorizedUser = () => {
        let newUser : AdditionalAuthorizedUserData = {
            full_name: '',
            email: '',
            contact_number: '',
        }
        let updatedAccounts = [...authorizedUsers]
        updatedAccounts.push(newUser)
        setAuthorizedUsers(updatedAccounts);
    }
    const removeNextAuthorizedUser = () => {
        const updatedAccounts = [...authorizedUsers]
        if (index + 1 < updatedAccounts.length) {
            // Remove 1 element at position index + 1
            updatedAccounts.splice(index + 1, 1);
        }
        setAuthorizedUsers(updatedAccounts);
    }
    return (
        <>
            <div className={s.row}>
                <div className={theme === 'dark' ? cn(s.thirdHeader, s.darkFont) : cn(s.thirdHeader, s.lightFont)}>Authorized User/Signatory #{index+1}</div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Name <sup>*</sup></p>
                    <TextField
                        name="full_name"
                        value={authorizedUser.full_name}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields && invalidInputFields.includes("Name")}
                        error={invalidInputFields && invalidInputFields.includes("Name") ? 'Please enter a valid name.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Email Address <sup>*</sup></p>
                    <TextField
                        name="email"
                        value={authorizedUser.email}
                        onChange={handleInputValueChange}
                        variant="xs"
                        type='email'
                        externalError={invalidInputFields && invalidInputFields.includes("Email Address")}
                        error={invalidInputFields && invalidInputFields.includes("Email Address") ? 'Please enter a valid email address.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Contact Number <sup>*</sup></p>
                    <TextField
                        name="contact_number"
                        value={authorizedUser.contact_number}
                        onChange={handleInputValueChange}
                        variant="xs"
                        type='number'
                        externalError={invalidInputFields && invalidInputFields.includes("Contact Number")}
                        error={invalidInputFields && invalidInputFields.includes("Contact Number") ? 'Please enter a valid contact number.' : undefined}
                    />
                </div>
            </div>
            <div className={cn(s.row, s.addMargin)}>
                <Divider className={theme === 'dark'? s.sectionDividerDark : s.sectionDividerLight }/>
            </div>
            <div className={cn(s.addMoreMargin, s.toRight)}>
                {authorizedUsers[index + 1] ? 
                <Button
                    leftElement={<RemoveIcon />}
                    danger
                    className={s.button}
                    size="xs"
                    onClick={() => removeNextAuthorizedUser()}
                >
                    Remove Authorized User {index+2}
                </Button>
                :<Button
                    leftElement={<AddIcon />}
                    className={s.button}
                    size="xs"
                    onClick={addNextAuthorizedUser}
                >
                    Add Another Authorized User
                </Button>}
            </div>
        </>
    );
}

export default AuthorizedUsersForm;