import React, { useState } from 'react';
import cn from 'classnames';
import { 
    Button, 
    TagButton,
    ButtonGroup,
    Icon,
    TextField,
    RadioButtons,
    Checkbox,
    IconNamesType,
} from '../../components/UILib';
import icons from '../../icons';
import s from './UILibPage.module.scss';

export interface UILibPageProps {
    className?: string;
}

const UILibPage: React.FC<UILibPageProps> = ({ className }) => {
    const [text, setText] = useState('');
    const [selectedItem, handleSelectedItem] = useState('1'); // for radioButtons
    const radioChange = (event: React.ChangeEvent<HTMLInputElement>) => handleSelectedItem(event.target.value);

    const buttons = (
        <div className={cn(s.root, className)}>
            <h1>Buttons</h1>
            <div className={s.block}>
                <h2>variant</h2>
                <div className={s.col}>
                    <h3>regular(default)</h3>
                    <Button className={s.button} size="custom">
                        custom
                    </Button>
                    <Button className={s.button} size="xs">
                        xs size
                    </Button>
                    <Button className={s.button} size="sm">
                        sm size
                    </Button>
                    <Button className={s.button} size="md">
                        md size
                    </Button>
                    <Button className={s.button} size="lg">
                        lg size
                    </Button>
                    <Button className={s.button} size="xl">
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>branding</h3>
                    <Button variant="brand" className={s.button} size="custom">
                        custom
                    </Button>
                    <Button variant="brand" className={s.button} size="xs">
                        xs size
                    </Button>
                    <Button variant="brand" className={s.button} size="sm">
                        sm size
                    </Button>
                    <Button variant="brand" className={s.button} size="md">
                        md size
                    </Button>
                    <Button variant="brand" className={s.button} size="lg">
                        lg size
                    </Button>
                    <Button variant="brand" className={s.button} size="xl">
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>outline</h3>
                    <Button variant="outline" className={s.button} size="custom">
                        custom
                    </Button>
                    <Button variant="outline" className={s.button} size="xs">
                        xs size
                    </Button>
                    <Button variant="outline" className={s.button} size="sm">
                        sm size
                    </Button>
                    <Button variant="outline" className={s.button} size="md">
                        md size
                    </Button>
                    <Button variant="outline" className={s.button} size="lg">
                        lg size
                    </Button>
                    <Button variant="outline" className={s.button} size="xl">
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>branding outline</h3>
                    <Button variant="brand-outline" className={s.button} size="custom">
                        custom
                    </Button>
                    <Button variant="brand-outline" className={s.button} size="xs">
                        xs size
                    </Button>
                    <Button variant="brand-outline" className={s.button} size="sm">
                        sm size
                    </Button>
                    <Button variant="brand-outline" className={s.button} size="md">
                        md size
                    </Button>
                    <Button variant="brand-outline" className={s.button} size="lg">
                        lg size
                    </Button>
                    <Button variant="brand-outline" className={s.button} size="xl">
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>monochrome</h3>
                    <Button variant="monochrome" className={s.button} size="custom">
                        custom
                    </Button>
                    <Button variant="monochrome" className={s.button} size="xs">
                        xs size
                    </Button>
                    <Button variant="monochrome" className={s.button} size="sm">
                        sm size
                    </Button>
                    <Button variant="monochrome" className={s.button} size="md">
                        md size
                    </Button>
                    <Button variant="monochrome" className={s.button} size="lg">
                        lg size
                    </Button>
                    <Button variant="monochrome" className={s.button} size="xl">
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>clear</h3>
                    <Button variant="clear" className={s.button} size="custom">
                        custom
                    </Button>
                    <Button variant="clear" className={s.button} size="xs">
                        xs size
                    </Button>
                    <Button variant="clear" className={s.button} size="sm">
                        sm size
                    </Button>
                    <Button variant="clear" className={s.button} size="md">
                        md size
                    </Button>
                    <Button variant="clear" className={s.button} size="lg">
                        lg size
                    </Button>
                    <Button variant="clear" className={s.button} size="xl">
                        xl size
                    </Button>
                </div>
            </div>
            <hr />
            <div className={s.block}>
                <h2>danger</h2>
                <div className={s.col}>
                    <h3>regular(default)</h3>
                    <Button danger className={s.button} size="custom">
                        custom
                    </Button>
                    <Button danger className={s.button} size="xs">
                        xs size
                    </Button>
                    <Button danger className={s.button} size="sm">
                        sm size
                    </Button>
                    <Button danger className={s.button} size="md">
                        md size
                    </Button>
                    <Button danger className={s.button} size="lg">
                        lg size
                    </Button>
                    <Button danger className={s.button} size="xl">
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>outline</h3>
                    <Button danger variant="outline" className={s.button} size="custom">
                        custom
                    </Button>
                    <Button danger variant="outline" className={s.button} size="xs">
                        xs size
                    </Button>
                    <Button danger variant="outline" className={s.button} size="sm">
                        sm size
                    </Button>
                    <Button danger variant="outline" className={s.button} size="md">
                        md size
                    </Button>
                    <Button danger variant="outline" className={s.button} size="lg">
                        lg size
                    </Button>
                    <Button danger variant="outline" className={s.button} size="xl">
                        xl size
                    </Button>
                </div>
            </div>
            <hr />
            <div className={s.block}>
                <h2>With icon</h2>
                <div className={s.col}>
                    <h3>regular(default)</h3>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="custom"
                    >
                        custom
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="xs"
                    >
                        xs size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="sm"
                    >
                        sm size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="md"
                    >
                        md size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="lg"
                    >
                        lg size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="xl"
                    >
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>branding</h3>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="custom"
                        variant='brand-outline'
                    >
                        custom
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="xs"
                        variant='brand-outline'
                    >
                        xs size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="sm"
                        variant='brand-outline'
                    >
                        sm size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="md"
                        variant='brand-outline'
                    >
                        md size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="lg"
                        variant='brand-outline'
                    >
                        lg size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        className={s.button}
                        size="xl"
                        variant='brand-outline'
                    >
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>outline</h3>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="outline"
                        className={s.button}
                        size="custom"
                    >
                        custom
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="outline"
                        className={s.button}
                        size="xs"
                    >
                        xs size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="outline"
                        className={s.button}
                        size="sm"
                    >
                        sm size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="outline"
                        className={s.button}
                        size="md"
                    >
                        md size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="outline"
                        className={s.button}
                        size="lg"
                    >
                        lg size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="outline"
                        className={s.button}
                        size="xl"
                    >
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>monochrome</h3>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="monochrome"
                        className={s.button}
                        size="custom"
                    >
                        custom
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="monochrome"
                        className={s.button}
                        size="xs"
                    >
                        xs size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="monochrome"
                        className={s.button}
                        size="sm"
                    >
                        sm size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="monochrome"
                        className={s.button}
                        size="md"
                    >
                        md size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="monochrome"
                        className={s.button}
                        size="lg"
                    >
                        lg size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="monochrome"
                        className={s.button}
                        size="xl"
                    >
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>clear</h3>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="clear"
                        className={s.button}
                        size="custom"
                    >
                        custom
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="clear"
                        className={s.button}
                        size="xs"
                    >
                        xs size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="clear"
                        className={s.button}
                        size="sm"
                    >
                        sm size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="clear"
                        className={s.button}
                        size="md"
                    >
                        md size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="clear"
                        className={s.button}
                        size="lg"
                    >
                        lg size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        variant="clear"
                        className={s.button}
                        size="xl"
                    >
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>regular(default)</h3>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        className={s.button}
                        size="custom"
                    >
                        custom
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        className={s.button}
                        size="xs"
                    >
                        xs size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        className={s.button}
                        size="sm"
                    >
                        sm size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        className={s.button}
                        size="md"
                    >
                        md size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        className={s.button}
                        size="lg"
                    >
                        lg size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        className={s.button}
                        size="xl"
                    >
                        xl size
                    </Button>
                </div>
                <div className={s.col}>
                    <h3>outline</h3>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        variant="outline"
                        className={s.button}
                        size="custom"
                    >
                        custom
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        variant="outline"
                        className={s.button}
                        size="xs"
                    >
                        xs size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        variant="outline"
                        className={s.button}
                        size="sm"
                    >
                        sm size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        variant="outline"
                        className={s.button}
                        size="md"
                    >
                        md size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        variant="outline"
                        className={s.button}
                        size="lg"
                    >
                        lg size
                    </Button>
                    <Button
                        leftElement={<Icon name="home" />}
                        danger
                        variant="outline"
                        className={s.button}
                        size="xl"
                    >
                        xl size
                    </Button>
                </div>
            </div>
            <hr />
            <div className={s.block}>
                <h2>disabled</h2>
                <div className={s.col}>
                <Button disabled className={s.button} size="custom">
                    custom
                </Button>
                <Button disabled className={s.button} size="xs">
                    xs size
                </Button>
                <Button disabled className={s.button} size="sm">
                    sm size
                </Button>
                <Button disabled className={s.button} size="md">
                    md size
                </Button>
                <Button disabled className={s.button} size="lg">
                    lg size
                </Button>
                <Button disabled className={s.button} size="xl">
                    xl size
                </Button>
                </div>
                <div className={s.col}>
                <Button disabled variant="outline" className={s.button} size="custom">
                    custom
                </Button>
                <Button disabled variant="outline" className={s.button} size="xs">
                    xs size
                </Button>
                <Button disabled variant="outline" className={s.button} size="sm">
                    sm size
                </Button>
                <Button disabled variant="outline" className={s.button} size="md">
                    md size
                </Button>
                <Button disabled variant="outline" className={s.button} size="lg">
                    lg size
                </Button>
                <Button disabled variant="outline" className={s.button} size="xl">
                    xl size
                </Button>
                </div>
                <div className={s.col}>
                <Button
                    disabled
                    variant="monochrome"
                    className={s.button}
                    size="custom"
                >
                    custom
                </Button>
                <Button disabled variant="monochrome" className={s.button} size="xs">
                    xs size
                </Button>
                <Button disabled variant="monochrome" className={s.button} size="sm">
                    sm size
                </Button>
                <Button disabled variant="monochrome" className={s.button} size="md">
                    md size
                </Button>
                <Button disabled variant="monochrome" className={s.button} size="lg">
                    lg size
                </Button>
                <Button disabled variant="monochrome" className={s.button} size="xl">
                    xl size
                </Button>
                </div>
                <div className={s.col}>
                <Button disabled variant="clear" className={s.button} size="custom">
                    custom
                </Button>
                <Button disabled variant="clear" className={s.button} size="xs">
                    xs size
                </Button>
                <Button disabled variant="clear" className={s.button} size="sm">
                    sm size
                </Button>
                <Button disabled variant="clear" className={s.button} size="md">
                    md size
                </Button>
                <Button disabled variant="clear" className={s.button} size="lg">
                    lg size
                </Button>
                <Button disabled variant="clear" className={s.button} size="xl">
                    xl size
                </Button>
                </div>
                <div className={s.col}>
                <Button disabled danger className={s.button} size="custom">
                    custom
                </Button>
                <Button disabled danger className={s.button} size="xs">
                    xs size
                </Button>
                <Button disabled danger className={s.button} size="sm">
                    sm size
                </Button>
                <Button disabled danger className={s.button} size="md">
                    md size
                </Button>
                <Button disabled danger className={s.button} size="lg">
                    lg size
                </Button>
                <Button disabled danger className={s.button} size="xl">
                    xl size
                </Button>
                </div>
                <div className={s.col}>
                <Button
                    disabled
                    danger
                    variant="outline"
                    className={s.button}
                    size="custom"
                >
                    custom
                </Button>
                <Button
                    disabled
                    danger
                    variant="outline"
                    className={s.button}
                    size="xs"
                >
                    xs size
                </Button>
                <Button
                    disabled
                    danger
                    variant="outline"
                    className={s.button}
                    size="sm"
                >
                    sm size
                </Button>
                <Button
                    disabled
                    danger
                    variant="outline"
                    className={s.button}
                    size="md"
                >
                    md size
                </Button>
                <Button
                    disabled
                    danger
                    variant="outline"
                    className={s.button}
                    size="lg"
                >
                    lg size
                </Button>
                <Button
                    disabled
                    danger
                    variant="outline"
                    className={s.button}
                    size="xl"
                >
                    xl size
                </Button>
                </div>
                <div className={s.col}>
                <Button disabled variant="clear" className={s.button} size="custom">
                    custom
                </Button>
                <Button disabled variant="clear" className={s.button} size="xs">
                    xs size
                </Button>
                <Button disabled variant="clear" className={s.button} size="sm">
                    sm size
                </Button>
                <Button disabled variant="clear" className={s.button} size="md">
                    md size
                </Button>
                <Button disabled variant="clear" className={s.button} size="lg">
                    lg size
                </Button>
                <Button disabled variant="clear" className={s.button} size="xl">
                    xl size
                </Button>
                </div>
            </div>
            <hr />
            </div>
        );
        
        const tags = (
            <div className={cn(s.root, className)}>
            <h1>Tags</h1>
            <div className={s.block}>
                <h2>primary</h2>
                <div className={s.col}>
                <h3>grape(default)</h3>
                <TagButton className={s.button}>tag button</TagButton>
                <TagButton className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton className={s.button} uppercase tail="42" icon="home">
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <h3>gold</h3>
                <TagButton color="gold" className={s.button}>
                    tag button
                </TagButton>
                <TagButton color="gold" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton color="gold" className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <h3>silver</h3>
                <TagButton color="silver" className={s.button}>
                    tag button
                </TagButton>
                <TagButton color="silver" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton color="silver" className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <h3>green</h3>
                <TagButton color="green" className={s.button}>
                    tag button
                </TagButton>
                <TagButton color="green" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton color="green" className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <h3>red</h3>
                <TagButton color="red" className={s.button}>
                    tag button
                </TagButton>
                <TagButton color="red" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton color="red" className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton
                    color="red"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
            </div>
            <div className={s.block}>
                <h2>active</h2>
                <div className={s.col}>
                <TagButton active className={s.button}>
                    tag button
                </TagButton>
                <TagButton active className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton active className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton
                    active
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton active color="gold" className={s.button}>
                    tag button
                </TagButton>
                <TagButton active color="gold" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    active
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    active
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton active color="silver" className={s.button}>
                    tag button
                </TagButton>
                <TagButton active color="silver" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    active
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    active
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton active color="green" className={s.button}>
                    tag button
                </TagButton>
                <TagButton active color="green" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    active
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    active
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton active color="red" className={s.button}>
                    tag button
                </TagButton>
                <TagButton active color="red" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    active
                    color="red"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    active
                    color="red"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
            </div>
            <div className={s.block}>
                <h2>badge</h2>
                <div className={s.col}>
                <TagButton badge className={s.button}>
                    tag button
                </TagButton>
                <TagButton badge className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton badge className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton badge className={s.button} uppercase tail="42" icon="home">
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton badge color="gold" className={s.button}>
                    tag button
                </TagButton>
                <TagButton badge color="gold" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    badge
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    badge
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton badge color="silver" className={s.button}>
                    tag button
                </TagButton>
                <TagButton badge color="silver" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    badge
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    badge
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton badge color="green" className={s.button}>
                    tag button
                </TagButton>
                <TagButton badge color="green" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    badge
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    badge
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton badge color="red" className={s.button}>
                    tag button
                </TagButton>
                <TagButton badge color="red" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton badge color="red" className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton
                    badge
                    color="red"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
            </div>
        
            <div className={s.block}>
                <h2>small</h2>
                <div className={s.col}>
                <TagButton size="sm" className={s.button}>
                    tag button
                </TagButton>
                <TagButton size="sm" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton size="sm" className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton size="sm" color="gold" className={s.button}>
                    tag button
                </TagButton>
                <TagButton size="sm" color="gold" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    size="sm"
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton size="sm" color="silver" className={s.button}>
                    tag button
                </TagButton>
                <TagButton size="sm" color="silver" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    size="sm"
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton size="sm" color="green" className={s.button}>
                    tag button
                </TagButton>
                <TagButton size="sm" color="green" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    size="sm"
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton size="sm" color="red" className={s.button}>
                    tag button
                </TagButton>
                <TagButton size="sm" color="red" className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton
                    size="sm"
                    color="red"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    color="red"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
            </div>
            <div className={s.block}>
                <div className={s.col}>
                <TagButton size="sm" active className={s.button}>
                    tag button
                </TagButton>
                <TagButton size="sm" active className={s.button} uppercase>
                    uppercase
                </TagButton>
                <TagButton size="sm" active className={s.button} uppercase tail="42">
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton size="sm" active color="gold" className={s.button}>
                    tag button
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="gold"
                    className={s.button}
                    uppercase
                >
                    uppercase
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="gold"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton size="sm" active color="silver" className={s.button}>
                    tag button
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="silver"
                    className={s.button}
                    uppercase
                >
                    uppercase
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="silver"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton size="sm" active color="green" className={s.button}>
                    tag button
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="green"
                    className={s.button}
                    uppercase
                >
                    uppercase
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="green"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
                <div className={s.col}>
                <TagButton size="sm" active color="red" className={s.button}>
                    tag button
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="red"
                    className={s.button}
                    uppercase
                >
                    uppercase
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="red"
                    className={s.button}
                    uppercase
                    tail="42"
                >
                    with tail
                </TagButton>
                <TagButton
                    size="sm"
                    active
                    color="red"
                    className={s.button}
                    uppercase
                    tail="42"
                    icon="home"
                >
                    with tail
                </TagButton>
                </div>
            </div>
            </div>
        );
    const buttonGroups = (
        <div className={cn(s.root, className)}>
            <h1>Button Groups</h1>
            <div className={s.block}>
                <div className={s.button}>
                <ButtonGroup
                    size="xl"
                    uppercase
                    buttons={[
                    { text: 'yes', label: { text: '75%', color: 'green' } },
                    { text: 'no', label: { text: '25%', color: 'red' } },
                    ]}
                />
                </div>
                <div className={s.button}>
                <ButtonGroup
                    size="lg"
                    uppercase
                    buttons={[
                    { text: 'yes', label: { text: '75%', color: 'green' } },
                    { text: 'no', label: { text: '25%', color: 'red' } },
                    ]}
                />
                </div>
                <div className={s.button}>
                <ButtonGroup
                    size="md"
                    uppercase
                    buttons={[
                    { text: 'yes', label: { text: '75%', color: 'green' } },
                    { text: 'no', label: { text: '25%', color: 'red' } },
                    ]}
                />
                </div>
                <div className={s.button}>
                <ButtonGroup
                    size="sm"
                    inactive
                    uppercase
                    buttons={[
                    { text: 'yes', label: { text: '75%', color: 'green' } },
                    { text: 'no', label: { text: '25%', color: 'red' } },
                    ]}
                />
                </div>
                <div className={s.button}>
                <ButtonGroup
                    size="xs"
                    disabled
                    uppercase
                    buttons={[
                    { text: 'yes', label: { text: '75%', color: 'green' } },
                    { text: 'no', label: { text: '25%', color: 'red' } },
                    ]}
                />
                </div>
            </div>
        </div>
    );
    const textField = (
        <div className={cn(s.root, className)}>
            <h1>TextField</h1>
            <div className={s.col}>
                <TextField
                    label="Label"
                    name="textfield"
                    value={text}
                    onChange={setText}
                    variant="lg"
                />
            </div>
            <div className={s.col}>
                <TextField
                    label="Label"
                    name="textfield"
                    value={text}
                    onChange={setText}
                    variant="md"
                />
            </div>
            <div className={s.col}>
                <TextField
                    label="Label"
                    name="textfield"
                    value={text}
                    onChange={setText}
                    variant="sm"
                    helperText="helper text"
                />
            </div>
            <div className={s.col}>
                <TextField
                    label="Label"
                    name="textfield"
                    value={text}
                    onChange={setText}
                    variant="xs"
                    error="Error text"
                />
            </div>
        </div>
    );
    const radioOptionList = [{value: 'option1', label: 'Option 1'}, {value: 'option2', label: 'Option 2'}]
    const radioButtons = (
        <div className={cn(s.root, className)}>
            <h1>Radio buttons</h1>
            <div className={s.block}>
                <RadioButtons radioGroupLabel='Options' optionList={radioOptionList} onChange={radioChange} value={selectedItem}/>
            </div>
        </div>
    );
    

    const [checked, handleChecked] = useState(false);
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChecked(event.target.checked);
    };

    const checkboxes = (
        <div className={cn(s.root, className)}>
            <h1>Checkboxes</h1>
            <div className={s.block}>
                <div className={s.col}>
                <Checkbox onChange={handleCheckboxChange} checked={checked} />
                </div>
                <div className={s.col}>
                <Checkbox disabled />
                </div>
                <div className={s.col}>
                <Checkbox disabled checked />
                </div>
            </div>
        </div>
    );
    const iconsList = (
        <div className={cn(s.root, className)}>
            <h1>Icons</h1>
            <table className={s.iconTable}>
                <tbody>
                {Object.keys(icons).map((name) => {
                    const iconName = name as IconNamesType;
        
                    return (
                    <tr key={name}>
                        <td style={{color: 'white', textAlign: 'left'}}>{iconName}</td>
                        <td>
                            <Icon name={iconName} />
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
    
    return (
        <>
            {buttons}
            {/* {tags} */}
            {textField}
            {radioButtons}
            {checkboxes}
            {iconsList}
        </>
    )
}

export default UILibPage;