export const fiatCurrencies = [
    {
        value: 'USD',
        label: 'USD',
    },
    {
        value: 'CAD',
        label: 'CAD',
    },
    {
        value: 'GBP',
        label: 'GBP',
    },
    {
        value: 'EUR',
        label: 'EUR',
    }
]