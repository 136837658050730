export const legalEntityTypes = [
    {value: 'Corporation', label:'Corporation' },
    {value: 'Limited Liability Company (LLC)', label:'Limited Liability Company (LLC)' },
    {value: 'Limited Partnership (LP)', label:'Limited Partnership (LP)' },
    {value: 'Limited Liability Partnership (LLP)', label:'Limited Liability Partnership (LLP)' },
    {value: 'Limited Liability Limited Partnership (LLLP)', label:'Limited Liability Limited Partnership (LLLP)' },
    {value: 'Trust', label:'Trust' },
    {value: 'General Partnership (GP)', label:'General Partnership (GP)' },
    {value: 'Family Limited Partnership (FLP)', label:'Family Limited Partnership (FLP)' },
    {value: 'Sole Proprietorship', label:'Sole Proprietorship' },
    // {value: 'Other', label:'Other' },
]